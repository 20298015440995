import { Button } from "antd";
import React, { PureComponent } from "react";


class FlashBtn extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			flashing: false
		}
	}

	componentDidMount () {
		this.flashingTimer = setInterval(() => {
			this.setState({
				flashing: !this.state.flashing,
			});
		}, 500);
	}
	render () {
		if (!this.props.isMobile) {
			return (
				<Button
					className="flash-but"
					type="primary"
					style={{
						height: 'auto',
						background: !this.state.flashing
							? "rgba(42, 163, 242)"
							: "#dcebf9",
						borderColor: !this.state.flashing
							? "rgba(42, 163, 242)"
							: "#dcebf9",
						boxShadow: this.state.flashing ? "none" : undefined,
						transition: "none",
						borderTopRightRadius: 25,
						borderBottomLeftRadius: 25,
					}}
					onClick={this.props.onClick}
				>
					<div className="flash-btn-text"
						style={{
							color: this.state.flashing ? "#00aef7" : "#fff",
						}}
					>
						{"👆立即參加 !"}
						<br />
						{"免費糖尿心腎風險檢測"}
					</div>
				</Button>
			)
		}
		else {
			return (
				<div
					style={{
						position: "absolute",
						top: 35,
						left: 815,
						// width: 500,
						// height: 86,
						// background2: "#71b95375",
						cursor: "pointer",
					}}
				>
					<Button
						className="flash-but"
						type="primary"
						style={{
							// filter: "hue-rotate(145deg)",
							// width: 300,
							height: 90,
							width: 275,
							display: this.state.flashing ? "block" : "none",
							backgroundImage:
								"url(https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/bg-grey.png)",
							// fontSize: 24,
							// lineHeight: "32px",
							// boxShadow: this.state.flashing ? "none" : undefined,
							backgroundColor: "transparent",
							transition: "none",
							border: "unset",
							// borderTopRightRadius: 25,
							// borderBottomLeftRadius: 25,
						}}
						onClick={this.props.onClick}
					>
						<div></div>
					</Button>
					<Button
						className="flash-but"
						type="primary"
						style={{
							// filter: "hue-rotate(145deg)",
							// width: 300,
							height: 90,
							width: 275,
							display: !this.state.flashing ? "block" : "none",
							backgroundImage:
								"url(https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/bg-blue.png)",
							// fontSize: 24,
							// lineHeight: "32px",
							// boxShadow: this.state.flashing ? "none" : undefined,
							backgroundColor: "transparent",
							transition: "none",
							border: "unset",
							// borderTopRightRadius: 25,
							// borderBottomLeftRadius: 25,
						}}
						onClick={this.props.onClick}
					>
						<div></div>
					</Button>
				</div>
			)
		}

	}

	componentWillUnmount () {
		if (this.flashingTimer) {
			clearInterval(this.flashingTimer);
		}
	}
}

export default FlashBtn