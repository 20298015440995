import React, { PureComponent } from "react";
import FlashBtn from "./FlashBtn";
import forxigaGifImg from "../img/forxiga-gif-02.gif"
const forxigaGif = process.env.NODE_ENV === 'development' ? forxigaGifImg : '/sites/default/files/forxiga.gif'

class Banner extends PureComponent {

	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.state = {
			zoom: 1
		}
	}
	onresize () {
		const ZOOM = Math.min(document.body.scrollWidth / 1200, 1.2)

		this.setState({ zoom: ZOOM > 0.5 ? ZOOM : 0.5 })
	}
	changeVisible () {
		this.props.changeVisible()
	}
	componentDidMount () {
		window.addEventListener("resize", this.onresize.bind(this), false);
		this.onresize.bind(this)()
	}

	componentWillUnmount () {
		window.removeEventListener("resize", this.onresize, false);
	}

	render () {
		return <div className="home-banner" id={this.props.isMobile ? "head_mobile" : 'head'}>
			<div className="pc-btn">
				<div className="container">
					<div className="pc-title">
						<span className="red-text">您有正確</span>
						<br />
						守護心腎<span className="red-text">嗎？</span>
						<div className="pc-desc">
							糖尿病與心臟及腎臟併發症息息相關！<br />
							心腎健康方有精彩生活，<br />
							立即看看以下資訊，再進行自我檢查啦！
						</div>
					</div>
					<img src={forxigaGif}
						className="banner-img-btn"
						onClick={this.changeVisible.bind(this)}
						alt="立即參加 !糖尿健康抗疫行动" />
					<FlashBtn
						isMobile={false}
						onClick={this.props.bookingDisplay} />
				</div>
			</div>
			<img src={
				'https://login.az.yubis.net/sites/default/files/2022-04/forxiga_topbanner.png'
			} alt="" />
		</div>
	}
}

export default Banner
