import React, { PureComponent } from "react";
import { RBackground } from "./requester";
import { Questionnaire } from "./questionnaire";
import { VideoOverlay } from "./videoOverlay";
import { TipsOverlay } from "./tips";
import { Booking } from "./boki";
import { Control } from "./control";
import { Anchor, BackTop, Row, Col, Modal, Button } from "antd";
import Banner from "./components/Banner"
import { vids } from "./constant/index"
import UploadPhoto from "./components/uploadPhoto"
import AppPage from "./components/appPage"
import { getUrlParams } from "./utils/index"


const { Link } = Anchor;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.ref_mobile = React.createRef();
    this.anchorRef = React.createRef();
    this.state = {
      photoStyle: {},
      UploadPhotoStyle: {},
      UploadPhotoVisible: getUrlParams('action') === 'gallerypopup' || false
    };
  }
  setIframe(event) {
    const { type, style = {}, status = '1' } = event.data || '';
    switch (type) {
      case "photo":
        this.setState({
          photoStyle: {
            ...this.state.photoStyle,
            ...style,
          }
        });
        break;
      case "index":
        this.setState({
          UploadPhotoStyle: {
            ...this.state.UploadPhotoStyle,
            ...style,
          }
        });
        if (event.data.status) {
          this.setState({
            UploadPhotoVisible: (status + '') === '1'
          });
        }
        break;
      default:
        break;
    }
  }
  changeVisible() {
    this.setState({
      UploadPhotoVisible: !this.state.UploadPhotoVisible
    });
  }

  componentDidMount() {
    console.log("VER 0.23");
    let Fthis = this;
    this.setIframeHandler = this.setIframe.bind(this);
    window.addEventListener('message', this.setIframeHandler, false);
    this.setState({
      ref: this.ref,
      ref_mobile: this.ref_mobile,
    });
    this.displayQuestionnaire = function () {
      Fthis.setState({
        QuestionnaireDisplay: true,
      });
    };
    this.displayQuestionnaire_mobile = function () {
      Fthis.setState({
        QuestionnaireDisplayMoobile: true,
      });
    };
    this.displayTips = function () {
      Fthis.setState({
        tipsOverlayDisplay: true,
      });
    };
    this.displayVid1 = function () {
      Fthis.setState({
        vid1Display: true,
        vid1Display_mobile: false,
      });
    };
    this.displayVid1_mobile = function () {
      Fthis.setState({
        vid1Display_mobile: true,
        vid1Display: false,
      });
    };
    this.link = function (into) {
      let current = Fthis.anchorRef.current;
      let element = current.inkNode.parentElement.parentElement;
      let links = element.getElementsByClassName("ant-anchor-link-title");
      for (let item of links) {
        if (item.innerText == into) {
          setTimeout(() => {
            item.click();
          }, 0);

          return;
        }
      }
      console.error("anchor_not_find");
    };
    this.即将推出 = function () {
      Modal.info({
        title: "快將推出",
        onOk() { },
      });
    };

    if (navigator.userAgent.indexOf("Mobile") != -1) {
      // if (window.screen.orientation.angle != 0) {
      //   // Fthis.setState({ orientation: true })
      // }
      // window.addEventListener("orientationchange", function () {
      //   if (window.screen.orientation.angle != 0) {
      //     // Fthis.setState({ orientation: true })
      //   } else {
      //     Fthis.setState({ orientation: false })
      //   }
      // });
    }

    if (
      navigator.userAgent.indexOf("Android") != -1
      //  && (window.innerHeight > 500 || window.innerWidth > 768)
    ) {
      Fthis.setState({ forceMobile: true });
    }

    this.resize = function (force) {
      let innerWidth = window.innerWidth || 0;
      if (parseInt(innerWidth / 10) != this.innerWidth || force === true) {
        this.innerWidth = innerWidth;
        Fthis.setState({
          innerWidth,
        });
      }
    };
    window.addEventListener("resize", this.resize, false);
    document.addEventListener("fullscreenchange", () => {
      this.resize(true);
    });
    window.Fthis = this;

    // alert(window.innerHeight)
    const tagManagerArgs = {
      gtmId: "GTM-M6LMBSC",
      dataLayerName: "ReactPageDataLayer",
    };

    // TagManager.initialize(tagManagerArgs)
    document.getElementsByTagName("body")[0].style.WebkitTextSizeAdjust =
      "none";

    let applyHash = function (tag = "") {
      if (document.location.hash.length > 0) {
        console.debug("hash", document.location.hash.substring(1));
        setTimeout(() => {
          let hash = document.location.hash.substring(1).replace("_mobile", "");
          if (navigator.userAgent.indexOf("Mobile") != -1) {
            hash = hash + "_mobile";
          }
          try {
            if (window.hasScrolled) {
              console.debug(
                "link_skip_hasScrolled",
                hash,
                performance.now(),
                tag
              );
              return;
            }
            let top = document.getElementById(hash).getBoundingClientRect().top;
            if (top < 300) {
              console.debug("link_skip", hash, performance.now(), tag);
              return;
            }
          } catch (error) { }
          console.debug("link", hash, performance.now(), tag);
          Fthis.link(hash);
        }, 500);
      }
    };

    window.addEventListener("load", () => {
      applyHash("onload");
    });

    window.addEventListener("scroll", () => {
      console.debug("scroll");
      window.hasScrolled = true;
    });

    setTimeout(applyHash, 0);
    setTimeout(applyHash, 500);
    // setTimeout(applyHash, 1500);

    Fthis.keyLists = "";
    document.addEventListener("keydown", (event) => {
      let WAKE_KEY = "CONTROL".toLocaleUpperCase();
      let keyId = event.key.toLocaleUpperCase();
      if (keyId.length > 2) {
        return;
      }
      Fthis.keyLists += keyId;
      if (WAKE_KEY.indexOf(Fthis.keyLists) != 0) {
        Fthis.keyLists = "";
      } else {
        if (WAKE_KEY == Fthis.keyLists) {
          Fthis.setState({ controlON: true });
        }
      }
    });

    if (document.location.search.indexOf("turnonbooking") != -1) {
      Fthis.setState({
        BookingDisplay: true,
      });
    }
    if (document.location.search.indexOf("turnoncheck") != -1) {
      Fthis.setState({
        QuestionnaireDisplay: true,
      });
    }
    // this.flashingTimer = setInterval(() => {
    //   this.setState({
    //     flashing: !this.state.flashing,
    //   });
    // }, 500);
  }
  render() {
    let Fthis = this;
    let forceMobile = false;
    let forcePC = false;
    let Layout_md_PC = 24;
    let Layout_span_PC = 0;
    let Layout_md_mobile = 0;
    let Layout_span_mobile = 24;

    if (
      navigator.userAgent.indexOf("Android") != -1 ||
      navigator.userAgent.indexOf("iPad") != -1 ||
      (navigator.userAgent.indexOf("AppleWebKit") != -1 &&
        typeof document.ontouchend == "object")
      //  && (window.innerHeight > 768 || window.innerWidth > 768)
    ) {
      console.log("forceMobile");
      forceMobile = true;
      Layout_md_PC = 0;
      Layout_md_mobile = 24;
    }
    if (navigator.userAgent.indexOf("Mobile") == -1) {
      forcePC = true;
      Layout_span_PC = 24;
      Layout_span_mobile = 0;
    }

    let forcePCStyle = {};
    if (window.innerWidth < 965) {
      forcePCStyle = {
        marginLeft: -150,
        marginRight: -150,
        width: "calc(100% + 300px)",
      };
    }

    return (
      <div
        style={{
          fontFamily: 'gothic,"Helvetica Neue",Helvetica,Arial,sans-serif',
          maxWidth2: 1170,
          userSelect2: "none",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <UploadPhoto
          visible={this.state.UploadPhotoVisible}
          photoStyle={this.state.UploadPhotoStyle}
          onClose={this.changeVisible.bind(this)} />
        <Control visible={this.state.controlON} />
        <Booking
          visible={this.state.BookingDisplay}
          onCancel={() => {
            Fthis.setState({
              BookingDisplay: false,
            });
          }}
        />
        <div
          style={{
            position: "fixed",
            height: "100%",
            width: "100%",
            background: "#FFFFFF",
            zIndex: 9,
            textAlign: "center",
            fontSize: 32,
            display: this.state.orientation === true ? "block" : "none",
            top: 0,
            left: 0,
          }}
        >
          <br />
          <img
            src={"https://z3.ax1x.com/2021/04/19/cTPDxJ.png"}
            style={{ height: 100 }}
          />
          <br />
          <br />
          {"轉動手機以獲得最佳瀏覽體驗"}
          <br />
        </div>
        <div style={{ position: "absolute", display: "none" }}>
          <Anchor ref={this.anchorRef}>
            <Link href="#head" title="Basic demo" />
            <Link href="#Router" title="Basic demo" />
            <Link href="#Router_mobile" title="Router_mobile" />
            <Link href="#Overview" title="Basic demo" />
            <Link href="#Overview_mobile" title="Overview_mobile" />
            <Link href="#Video" title="Video" />
            <Link href="#Video_mobile" title="Video_mobile" />
            <Link href="#HeartFailure" title="HeartFailure" />
            <Link href="#HeartFailure_mobile" title="HeartFailure_mobile" />
            <Link href="#complication" title="Basic demo" />
            <Link href="#complication" title="complication" />
            <Link href="#Data2" title="Data2" />
            <Link href="#Data2_mobile" title="Data2_mobile" />
            <Link href="#How" title="How" />
            <Link href="#How_mobile" title="How_mobile" />
            <Link href="#Coffee" title="Coffee" />
            <Link href="#Coffee_mobile" title="Coffee_mobile" />
            <Link href="#Download" title="Download" />
            <Link href="#Download_mobile" title="Download_mobile" />
            <Link href="#pfooter" title="Basic demo" />
            <Link href="#pfooter_mobile" title="Basic demo" />
          </Anchor>
        </div>
        <BackTop />
        {/* https://en.wikipedia.org/wiki/Unicode_subscripts_and_superscripts#Superscripts_and_subscripts_block */}

        <TipsOverlay
          visible={this.state.tipsOverlayDisplay}
          onClose={() => {
            Fthis.setState({
              tipsOverlayDisplay: false,
            });
          }}
        />
        <Row style={{ width: "100%" }}>
          <Col
            md={Layout_md_PC}
            span={Layout_span_PC}
            style={{ width: "100%" }}
          >
            <div
              id="forxiga_343"
              ref={this.ref}
              style={{ width: "100%", ...forcePCStyle }}
            >
              <Banner isMobile={false}
                displayQuestionnaire={this.displayQuestionnaire}
                changeVisible={this.changeVisible.bind(this)}
                bookingDisplay={() => this.setState({ BookingDisplay: true })}
              />
              {/* <RBackground
                id={"head"}
                mainRef={this.state.ref}
                width={1920}
                height={587}
                url={"https://z3.ax1x.com/2021/04/07/c3T8A0.png"}
                style={{}}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 145,
                    left: 742,
                    color: "#DB2A36",
                    fontWeight: 800,
                  }}
                >
                  <div style={{ fontSize: 50 }}>
                    {"您有正確"}
                    <span style={{ fontSize: 90 }}>{"守護心腎"}</span>
                    {"嗎？"}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 140,
                      left: -29,
                      color: "#DB2A36",
                      fontWeight: 200,
                      textAlign: "center",
                      width: 700,
                    }}
                  >
                    <div style={{ fontSize: 25 }}>
                      {
                        "糖尿病與心臟及腎臟併發症息息相關！心腎健康方有精彩生活，"
                      }
                      <br />
                      {"立即看看以下資訊，再進行自我檢查啦！"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 75,
                    left: 1123,
                    color: "#DB2A36",
                    fontWeight: 800,
                    display: "none",
                  }}
                >
                  <Button
                    type="primary"
                    style={{
                      filter: "hue-rotate(145deg)",
                      width: 300,
                      height: 72,
                      fontSize: 26,
                    }}
                    onClick={this.displayQuestionnaire}
                  >
                    {"立即進行自我監察"}
                  </Button>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 60,
                    left: 866,
                    color: "#DB2A36",
                    fontWeight: 800,
                  }}
                >
                  <FlashBtn isMobile={flase} onClick={() => {
                    Fthis.setState({
                      BookingDisplay: true,
                    });
                  }} />
                </div>
              </RBackground> */}
              <RBackground
                id={"Router"}
                mainRef={this.state.ref}
                width={10000}
                height={2141}
                // url={"https://z3.ax1x.com/2021/04/07/c8eGan.png"}//live
                // url={"https://z3.ax1x.com/2021/04/27/g9DVc6.png"} //多一个按钮//完整菜单
                url={"https://s1.ax1x.com/2022/07/28/vCF0nx.png"} //去掉了视频按钮
                style={{ fontSize: 500 }}
              >
                {/* <div style={{ width: "100%", textAlign: "center", fontSize: 150 }}>{"PC"}</div> */}

                <div
                  style={{
                    position: "absolute",
                    top: 241,
                    left: 1995,
                    width: 1400,
                    height: 800,
                    background2: "#71b95375",
                    cursor: "pointer",
                    display: "none"
                  }}
                  onClick={() => {
                    Fthis.link("Video");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 143,
                    left: 3555,
                    width: 1400,
                    height: 800,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.link("HeartFailure");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 159,
                    left: 5065,
                    width: 1400,
                    height: 800,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.link("Data2");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 1122,
                    left: 3044,
                    width: 1400,
                    height: 800,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.即将推出("快將推出");
                    return;
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 1100,
                    left: 4601,
                    width: 1100,
                    height: 600,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 1089,
                    left: 5950,
                    width: 1300,
                    height: 700,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.link("Download");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 279,
                    left: 6587,
                    width: 1300,
                    height: 700,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={this.displayTips}
                />

                {/* <div style={{ position: "absolute", top: 273, left: 2397, width: 1400, height: 800, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("Video") }} />
                <div style={{ position: "absolute", top: 193, left: 3937, width: 1400, height: 800, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("HeartFailure") }} />
                <div style={{ position: "absolute", top: 221, left: 5413, width: 1400, height: 800, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("Data2") }} />
                <div style={{ position: "absolute", top: 1169, left: 3417, width: 1400, height: 800, background2: "#71b95375", cursor: "pointer" }} onClick={() => { this.即将推出('快將推出'); return; Fthis.link("Coffee") }} />
                <div style={{ position: "absolute", top: 1133, left: 4925, width: 1100, height: 600, background2: "#71b95375", cursor: "pointer" }} onClick={this.displayQuestionnaire} />
                <div style={{ position: "absolute", top: 1133, left: 6305, width: 1300, height: 700, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("Download") }} /> */}
              </RBackground>
              <AppPage photoStyle={{
                ...this.state.photoStyle,
                height: (this.state.photoStyle.height || 800) + 50
              }} />
              <RBackground
                id={"Overview"}
                mainRef={this.state.ref}
                width={1920}
                height={1488}
                url={"https://login.az.yubis.net/sites/default/files/2022-04/c8lYm8_updated_ok.png"}
                // url={"https://z3.ax1x.com/2021/04/07/c8lYm8.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/2022-04/c8lYm8_updated.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 74,
                    left: 831,
                    color: "#464646",
                    fontWeight: 800,
                    fontSize: 32,
                  }}
                >
                  {"香港的糖尿病概況"}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 364,
                    left: 609,
                    color: "#464646",
                    fontWeight: 800,
                    fontSize: 32,
                    textAlign: "center",
                    lineHeight: "56px",
                  }}
                >
                  {"因此積極控制糖尿病，並減低患心臟病和腎衰竭等"}
                  <br />
                  {"致命併發症的機會可說是極為重要。"}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 632,
                    left: 839,
                    color: "#464646",
                    fontWeight: 800,
                    fontSize: 18,
                    width: 266,
                    lineHeight: "33px",
                  }}
                >
                  {
                    "「心腎併發症」是糖尿病患者經常面對的一大挑戰。人體的心腎系統是交互影響，若這兩個器官的功能受損，可引發惡性循環³:"
                  }
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 1056,
                    left: 736,
                    color: "#464646",
                    fontWeight: 600,
                    fontSize: 30,
                    textAlign: "center",
                    lineHeight: "42px",
                    letterSpacing: 2,
                  }}
                >
                  {"簡單回答 12 條選擇題 ，"}
                  <br />
                  {"即可更了解自己的糖尿病併發症風險 !"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 1197,
                    left: 887,
                    color: "white",
                    fontWeight: 500,
                    fontSize: 30,
                  }}
                >
                  {"立即進行自我監察"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 1161,
                    left: 730,
                    width: 680,
                    height: 124,
                    background: "#fff",
                    cursor: "pointer",
                  }}
                />
                <div style={{ position: "absolute", top: 1176, left: 776 }}>
                  <Button
                    type="primary"
                    style={{
                      position: "absolute",
                      filter: "hue-rotate(145deg)",
                      width: 453,
                      height: 91,
                      fontSize: 30,
                      fontWeight: 300,
                      borderRadius: 10,
                    }}
                    onClick={this.displayQuestionnaire}
                  >
                    {"立即進行自我監察"}
                  </Button>
                </div>
                <Questionnaire
                  visible={Fthis.state.QuestionnaireDisplay}
                  onBooking={() => {
                    Fthis.setState({
                      BookingDisplay: true,
                    });
                  }}
                  onClose={() => {
                    Fthis.setState({
                      QuestionnaireDisplay: false,
                    });
                  }}
                />
              </RBackground>

              <RBackground
                id={"Video"}
                mainRef={this.state.ref}
                width={1920}
                // height={1258}
                height={1720}
                // url={"https://i.loli.net/2021/04/12/EIvJiaXNztFOYnH.png"}  //没有医生专访（三个视频）的图片版本
                url={"https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/video-pc.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/video-pc.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/video-pc22.png"}
                style={{ fontSize: 500 }}
                disable
              >
                {/* 视频标题 */}
                <div
                  style={{
                    position: "absolute",
                    top: 69,
                    left: 749,
                    color: "white",
                    fontWeight: 500,
                    fontSize: 32,
                    lineHeight: "58px",
                    textAlign: "center",
                  }}
                >
                  {"「糖尿…不只是糖尿」紀錄片"}
                  <br />
                  <span style={{ fontSize: 20 }}>
                    {"過來人為你剖白患上糖尿心腎併發症的心路歷程"}
                  </span>
                </div>

                {/* 3个子视频 */}
                {/* <div style={{ display: "none" }}> */}
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: 1049,
                      left: 393,
                      width: 360,
                      height: 388,
                      background2: "#71b95375",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      Fthis.setState({ vid1Display: "vid1" });
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 200,
                        backgroundImage: `url(https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/v1.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />

                    <div
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 219,
                        width: 278,
                        fontSize: 24,
                        lineHeight: "33px",
                        fontSize: 24,
                        fontWeight: 600,
                        letterSpacing: 1,
                      }}
                    >
                      {"謹記控糖鐵三角-糖尿管理好簡單!"}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 299,
                        width: 300,
                        fontSize: 24,
                      }}
                    >
                      {"盧國榮醫生"}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 1049,
                      left: 783,
                      width: 360,
                      height: 388,
                      background2: "#71b95375",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      Fthis.setState({ vid1Display: "vid2" });
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 200,
                        backgroundImage: `url(https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/v2.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 219,
                        width: 278,
                        fontSize: 24,
                        lineHeight: "33px",
                        fontWeight: 600,
                        letterSpacing: 1,
                      }}
                    >
                      {"無聲殺手 – 細說糖尿心衰竭"}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 299,
                        width: 300,
                        fontSize: 24,
                      }}
                    >
                      {"梁達智醫生"}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 1049,
                      left: 1173,
                      width: 360,
                      height: 388,
                      background2: "#71b95375",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      Fthis.setState({ vid1Display: "vid3" });
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 200,
                        backgroundImage: `url(https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/v3.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 219,
                        width: 278,
                        fontSize: 24,
                        lineHeight: "33px",
                        fontSize: 24,
                        fontWeight: 600,
                        letterSpacing: 1,
                      }}
                    >
                      {"及早預防 - 糖尿腎衰竭檢測方法你要知！"}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 299,
                        width: 300,
                        fontSize: 24,
                      }}
                    >
                      {"鄧智偉教授"}
                    </div>
                  </div>

                  <VideoOverlay
                    popup={true}
                    visible={this.state.vid1Display === "vid1"}
                    // uid={"k3vk9754t3O5tJwYwQD"}
                    video={vids.Forxiga_Dr_Leung_final09}
                    onClose={() => {
                      console.log("cloase");
                      Fthis.setState({
                        vid1Display: false,
                      });
                    }}
                  />
                  <VideoOverlay
                    popup={true}
                    visible={this.state.vid1Display === "vid2"}
                    // uid={"k6FLIyTdrdu50QwYwUE"}
                    video={vids.Forxiga_Dr_Lo_final09}

                    onClose={() => {
                      console.log("cloase");
                      Fthis.setState({
                        vid1Display: false,
                      });
                    }}
                  />
                  <VideoOverlay
                    popup={true}
                    visible={this.state.vid1Display === "vid3"}
                    // uid={"k1ciSjgaJRz6XGwYwYx"}
                    video={vids.Forxiga_Prof_Tang_final09}

                    onClose={() => {
                      console.log("cloase");
                      Fthis.setState({
                        vid1Display: false,
                      });
                    }}
                  />
                </div>

                {/* 单独小视频 */}
                <div
                  style={{
                    position: "absolute",
                    top: 244,
                    left: 411,
                    width: 1096,
                    height: 618,
                    backgroundRepeat: "no-repeat",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={this.displayVid1}
                >
                  <div style={{ width: "100%", height: "100%", zoom: 0.1, position: "relative" }}>
                    <VideoOverlay
                      // uid={"k4WToeAKUgaSHrwOIc8"} 
                      video={vids.master}
                    />
                  </div>
                </div>
                {/* 放映机图片 */}
                <div
                  className={"aaaza-buttom-1"}
                  style={{
                    position: "absolute",
                    top: 767,
                    left: 254,
                    width: 595,
                    height: 198,
                    background: `url(${"https://z3.ax1x.com/2021/04/16/cfK6G8.png"})`,
                    backgroundRepeat: "no-repeat",
                    borderRadius: 10,
                    cursor: "pointer",
                    pointerEvents: "none",
                  }}
                />
                {/* <div style={{ position: "absolute", top: 1048 + 466, left: 667, width: 580, height: 140, background: "#117DB3", cursor: "pointer" }} /> */}
                {/* <div
                  style={{
                    position: "absolute",
                    top: 1048,
                    left: 667,
                    width: 580,
                    height: 140,
                    background: "#117DB3",
                    cursor: "pointer",
                  }}
                /> */}

                {/* b 医生专访code hk */}
                <div
                  style={{ width: "100%", height: 0, }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "1466px",
                      fontSize: 14,
                      width: "100%",
                      textAlign: "center",
                      background2: "#71b95375",
                      color: "white"
                    }}
                  >
                    HK-5624 05/07/2021
                  </div>
                </div>
                {/* e 医生专访code hk */}

                <div
                  className={"aaaza-buttom-1 2"}
                  style={{
                    position: "absolute",
                    // top: 1049,
                    top: 1049 + 466,
                    left: 689,
                    width: 545,
                    height: 140,
                    backgroundRepeat: "no-repeat",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire}
                />
                {/* <div
                  style={{
                    position: "absolute",
                    top: 1542 - 467,
                    left: 847,
                    color: "white",
                    fontWeight: 500,
                    fontSize: 40,
                    lineHeight: "43px",
                    cursor: "pointer",
                    pointerEvents: "none",
                  }}
                > */}
                <div style={{ position: "absolute", top: 1542, left: 847, color: "white", fontWeight: 500, fontSize: 40, lineHeight: "43px", cursor: "pointer", pointerEvents: "none" }}>
                  {"立即檢測 !"}
                  <br />
                  <span style={{ fontSize: 26 }}>
                    {"了解自己的糖尿病併發症風險 !"}
                  </span>
                </div>
              </RBackground>

              <RBackground
                id={"HeartFailure"}
                mainRef={this.state.ref}
                width={1920}
                height={675}
                url={"https://login.az.yubis.net/sites/default/files/2022-04/c8dgYV_edited_ok.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/2022-04/c8dgYV_edited.png"}
                style={{ fontSize: 500, transform: 'translateY(-25px)' }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 85,
                    left: 0,
                    right: 0,
                    color: "white",
                    fontWeight: 500,
                    fontSize: 32,
                    lineHeight: "58px",
                    textAlign: "center",
                  }}
                >
                  {"糖尿病與心衰竭⁴"}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 161,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: "center",
                    color: "white",
                    fontWeight: 500,
                    fontSize: 18,
                    width: 565,
                    lineHeight: "30px",
                  }}
                >
                  {
                    "糖尿病患者血液中的高血糖，可使動脈血管壁蛋白糖化，血管壁易受損並形成動脈血管硬化及增厚，血管狹窄使血液難以輸送到心臟肌肉，心肌因而無法獲得足夠的血液與養分，而受損的血管也造成血塊容易沉澱於心臟冠狀動脈處，這些都可導致多種心血管疾病甚至心臟衰竭。"
                  }
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 333,
                    left: 675,
                    color: "#e43d3f",
                    fontWeight: 800,
                    fontSize: 24,
                    letterSpacing: "1.5px",
                    lineHeight: "40px",
                  }}
                >
                  {"心血管疾病"}
                  <span style={{ color: "#0268ad" }}>
                    {"其實是包含多種"}
                    <br />
                    {"心臟和血管疾病的總稱，"}
                    <br />
                    {"當中包括："}
                  </span>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 334,
                    left: 1050,
                    color: "#464646",
                    fontWeight: 500,
                    fontSize: 20,
                  }}
                >
                  <b
                    style={{
                      fontWeight: 900,
                      textShadow:
                        "0 0 0px rgb(70 70 70), 0.5px 0.5px 0px rgb(70 70 70)",
                    }}
                  >
                    {"· 慢性心臟衰竭"}
                  </b>
                  <br />
                  {"· 冠狀動脈疾病 "}
                  <br />
                  {"· 心肌梗塞"}
                  <br />
                  {"· 中風"}
                  <br />
                  {"· 周邊動脈疾病"}
                </div>
              </RBackground>

              <RBackground
                id="complication"
                mainRef={this.state.ref}
                width={1920}
                height={1220}
                url={"https://z3.ax1x.com/2021/04/13/croJc4.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 83,
                    left: 844,
                    color: "#464646",
                    fontWeight: 500,
                    fontSize: 24,
                    lineHeight: "58px",
                    textAlign: "center",
                  }}
                >
                  {"心臟衰竭併發症的數字"}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 510,
                    left: 856,
                    color: "#464646",
                    fontWeight: 500,
                    fontSize: 24,
                    lineHeight: "58px",
                    textAlign: "center",
                  }}
                >
                  {"掌握病徵及高危因素"}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 1022,
                    left: 667,
                    width: 580,
                    height: 140,
                    background: "#fff",
                    cursor: "pointer",
                  }}
                />
                <div
                  className={"aaaza-buttom-2"}
                  style={{
                    position: "absolute",
                    top: 1020,
                    left: 688,
                    width: 545,
                    height: 136,
                    backgroundRepeat: "no-repeat",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire}
                />

                <div
                  style={{
                    position: "absolute",
                    top: 1040,
                    left: 850,
                    color: "white",
                    fontWeight: 500,
                    fontSize: 35,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 3.5,
                    pointerEvents: "none",
                  }}
                >
                  {"立即檢測 !"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 1087,
                    left: 846,
                    color: "white",
                    fontWeight: 500,
                    fontSize: 25,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                    pointerEvents: "none",
                  }}
                >
                  {"了解自己的糖尿病併發症風險 !"}
                </div>
                {/* <div style={{ position: "absolute", top: 1023, left: 688, width: 548, height: 137, background2: "#71b95375", cursor: "pointer" }} onClick={this.displayQuestionnaire} /> */}
              </RBackground>

              <RBackground
                id={"Data2"}
                mainRef={this.state.ref}
                width={1920}
                height={770}
                // url={"https://z3.ax1x.com/2021/04/13/crbFbj.png"}
                url={"https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/forxigaBK-7.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 55,
                    left: 852,
                    color: "#000000",
                    fontWeight: 800,
                    fontSize: 31,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                  }}
                >
                  {"糖尿病與腎衰竭¹⁰"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 139,
                    left: 389,
                    color: "#000000",
                    fontWeight: 800,
                    fontSize: 18,
                    lineHeight: "35px",
                    textAlign: "center",
                  }}
                >
                  {
                    "糖尿病患者的腎臟若長期過濾高糖份血液並且血壓過高，會破壞腎臟內的腎小球並使腎臟細胞出現結構性破壞，甚至併發腎小球病變和腎衰竭，腎"
                  }
                  <br />
                  {
                    "功能最終可受損而無法維持正常過濾功能。而糖尿病是腎衰竭的主因¹¹，因此糖尿病患者應定期進行腎功能評估，及早預防糖尿腎衰竭。"
                  }
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 239,
                    left: 846,
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: 22,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 2.1,
                  }}
                >
                  {"心臟衰竭併發症的數字!"}
                </div>
              </RBackground>

              <RBackground
                id={"How"}
                mainRef={this.state.ref}
                width={1920}
                height={1778}
                url={"https://login.az.yubis.net/sites/default/files/2022-04/forxigaBK_edited_ok.png"}
                // url={"https://login.az.yubis.net/sites/default/files/2022-04/forxigaBK_edited_ok.png"}
                // url={"https://z3.ax1x.com/2021/04/08/cJlh1s.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/forxigaBK-8.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/2022-04/forxigaBK_edited.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 64,
                    right: 0,
                    left: 0,
                    color: "#000000",
                    fontWeight: 800,
                    fontSize: 31,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                  }}
                >
                  {"如何知道腎臟出現病變¹⁴？"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 132,
                    right: 0,
                    left: 0,
                    color: "#136e99",
                    fontWeight: 800,
                    fontSize: 23,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                  }}
                >
                  {"評估方法一：估算腎小球過濾率(eGFR)"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 197,
                    right: 0,
                    left: 0,
                    color: "#464646",
                    fontWeight: 300,
                    fontSize: 18,
                    lineHeight: "34px",
                    textAlign: "center",
                  }}
                >
                  {
                    "eGFR (Estimated Glomerular Filtration Rate) 是一種檢測慢性腎病的指"
                  }
                  <br />
                  {
                    "標，根據血液中的肌酸酐、年齡、性別等計算出做出對腎功能的判斷。"
                  }
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 662,
                    right: 0,
                    left: 0,
                    color: "#136e99",
                    fontWeight: 800,
                    fontSize: 23,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                  }}
                >
                  {"評估方法二：「尿白蛋白/肌酐酸」比值檢測 (UACR)¹⁵"}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 732,
                    right: 0,
                    left: 0,
                    color: "#464646",
                    fontWeight: 300,
                    fontSize: 18,
                    lineHeight: "34px",
                    textAlign: "center",
                  }}
                >
                  {
                    "腎臟的過濾功能無法正常運作，蛋白質便可滲漏到尿液中。尿白蛋白肌"
                  }
                  <br />
                  {
                    "酐酸比值檢測便是可協助偵測出早期腎病及反映腎功能的有效方法，透"
                  }
                  <br />
                  {
                    "過量測及計算尿液中的蛋白質(或白蛋白)和肌酸酐的比值,來診斷腎功能"
                  }
                  <br />
                  {"是否有異常。"}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: 918,
                    right: 0,
                    left: 0,
                    color: "#464646",
                    fontWeight: 800,
                    fontSize: 23,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                  }}
                >
                  {"以「尿白蛋白/肌酐酸」比值作慢性腎病的分期¹⁶"}
                </div>
                <div style={{
                  position: "absolute",
                  top: 1452,
                  right: 0,
                  left: 0,
                }}>
                  <div
                    style={{
                      margin: 'auto',
                      color: "#464646",
                      fontWeight: 300,
                      fontSize: 18,
                      lineHeight: "36px",
                      textAlign: "center",
                      width: 858,
                    }}
                  >
                    {
                      "由於慢性腎病早期的病徵並不明顯，許多患者在接受例行身體檢查時，才發現不少腎小球已"
                    }
                    {
                      "壞死，腎功能已受到難以逆轉的破壞。因此國際腎病學會 (International Society of Nephrology) 建議糖尿病患者應每年接受"
                    }
                    {
                      "估算腎小球過濾率及蛋白尿檢測，有助於患病早期及早診斷慢性腎病，防止併發腎衰竭。¹⁷"
                    }
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 1571,
                    left: 688,
                    width: 548,
                    height: 137,
                    background: "#fff",
                    cursor: "pointer",
                  }}
                />
                <div
                  className={"aaaza-buttom-3"}
                  style={{
                    position: "absolute",
                    top: 1572,
                    left: 688,
                    width: 545,
                    height: 136,
                    backgroundRepeat: "no-repeat",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire}
                />

                <div
                  style={{
                    position: "absolute",
                    top: 1586,
                    right: 0,
                    left: 0,
                    color: "#fff",
                    fontWeight: 800,
                    fontSize: 38,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                    pointerEvents: "none",
                  }}
                >
                  {"盡早檢測 !"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 1636,
                    right: 0,
                    left: 0,
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: 25,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 0.9,
                    pointerEvents: "none",
                  }}
                >
                  {"了解自己的糖尿病併發症風險 ! "}
                </div>
                {/* <div style={{ position: "absolute", top: 1571, left: 688, width: 548, height: 137, background2: "#71b95375", cursor: "pointer" }} onClick={this.displayQuestionnaire} /> */}
              </RBackground>

              {/* <RBackground
                id={"Download"}
                mainRef={this.state.ref}
                width={1920}
                height={1010}
                url={"https://z3.ax1x.com/2021/04/08/cJcz7T.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 62,
                    left: 740,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: 31,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 2,
                  }}
                >
                  {"「控糖通識科 – 守護心腎篇」"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 128,
                    left: 485,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: 18,
                    lineHeight: "58px",
                    textAlign: "center",
                    letterSpacing: 2,
                  }}
                >
                  {
                    "書仔細細，內容豐富包括基本糖尿知識、心腎併發症專題、治療選擇、運動指南、低糖食譜、食物營養表等…"
                  }
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 849,
                    left: 809,
                    width: 303,
                    height: 90,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                // onClick={() => {
                //   // window.location.href =
                //   //   "./img/Forxiga Booklet_210712 (003).pdf";
                //   this.即将推出();
                // }}
                >
                  <a
                    href="https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/Forxiga_Booklet_210712003.pdf"
                    download="Forxiga_Booklet_210712003.pdf"
                    style={{ display: "block", width: "100%", height: "100%" }}
                  ></a>
                </div>
              </RBackground> */}

              {/* {inner} */}
            </div>
          </Col>
          <Col md={Layout_md_mobile} span={Layout_span_mobile}>
            <Banner isMobile={true}
              displayQuestionnaire={this.displayQuestionnaire}
              changeVisible={this.changeVisible.bind(this)}
              bookingDisplay={() => this.setState({ BookingDisplay: true })} />
            <div
              ref={this.ref_mobile}
              style={{
                marginLeft: "-21.5vw",
                marginRight: "-20vw",
              }}
            >
              <RBackground
                id={"Router_mobile"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={1700}
                // url={"https://z3.ax1x.com/2021/04/12/cB8DY9.png"}
                // url={"https://z3.ax1x.com/2021/04/27/g9DE1x.png"} //多一个按钮//完整菜单
                url={"https://s1.ax1x.com/2022/07/28/vCFbCQ.png"} //去掉了视频按钮
                style={{ fontSize: 500 }}
              >
                {/* <div style={{ width: "100%", textAlign: "center", fontSize: 150 }}>{"mobile"}</div> */}

                <div
                  style={{
                    position: "absolute",
                    top: 330,
                    left: 337,
                    width: 451,
                    height: 228,
                    background2: "#71b95375",
                    cursor: "pointer",
                    display:"none"
                  }}
                  onClick={() => {
                    Fthis.link("Video_mobile");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 330,
                    left: 869,
                    width: 451,
                    height: 228,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.link("HeartFailure_mobile");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 676,
                    left: 378,
                    width: 451,
                    height: 228,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.即将推出("快將推出");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 676,
                    left: 905,
                    width: 451,
                    height: 228,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 1026,
                    left: 905,
                    width: 451,
                    height: 228,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.link("Download_mobile");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 1001,
                    left: 333,
                    width: 451,
                    height: 228,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.link("Data2_mobile");
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 1328,
                    left: 610,
                    width: 451,
                    height: 228,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={this.displayTips}
                />

                {/* <div style={{ position: "absolute", top: 351, left: 264, width: 553, height: 398, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("Video_mobile") }} />
                <div style={{ position: "absolute", top: 330, left: 869, width: 559, height: 421, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("HeartFailure_mobile") }} />
                <div style={{ position: "absolute", top: 805, left: 271, width: 538, height: 361, background2: "#71b95375", cursor: "pointer" }} onClick={() => { this.即将推出('快將推出'); }} />
                <div style={{ position: "absolute", top: 801, left: 905, width: 487, height: 326, background2: "#71b95375", cursor: "pointer" }} onClick={this.displayQuestionnaire} />
                <div style={{ position: "absolute", top: 1221, left: 263, width: 497, height: 349, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("Data2_mobile") }} />
                <div style={{ position: "absolute", top: 1260, left: 905, width: 530, height: 338, background2: "#71b95375", cursor: "pointer" }} onClick={() => { Fthis.link("Download_mobile") }} /> */}
              </RBackground>
            </div>
            <AppPage photoStyle={{
              width: '100%',
              ...this.state.photoStyle,
              height: (this.state.photoStyle.height || 800) + 50
            }} />
            <div
              ref={this.ref_mobile}
              style={{
                marginLeft: "-21.5vw",
                marginRight: "-20vw",
              }}
            >
              <RBackground
                id={"Overview_mobile"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={4476}
                url={"https://login.az.yubis.net/sites/default/files/2022-04/cr7UFx_edited_ok.png"}
                // url={"https://z3.ax1x.com/2021/04/13/cr7UFx.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/2022-04/cr7UFx_edited.png"}
                style={{ fontSize: 500 }}
              >
                {/* <div style={{ position: "absolute", top: 3836, left: 298, width: 1128, height: 228, background2: "#71b95375", cursor: "pointer" }} onClick={this.displayQuestionnaire_mobile} /> */}
                <div
                  style={{
                    position: "absolute",
                    top: 3816,
                    left: 285,
                    width: 1158,
                    height: 263,
                    background: "#fff",
                    cursor: "pointer",
                  }}
                />
                <div style={{ position: "absolute", top: 3836, left: 298 }}>
                  <Button
                    type="primary"
                    style={{
                      position: "absolute",
                      filter: "hue-rotate(145deg)",
                      width: 453,
                      height: 91,
                      fontSize: 30,
                      fontWeight: 300,
                      borderRadius: 10,
                      zoom: 2.5,
                    }}
                    onClick={this.displayQuestionnaire}
                  >
                    {"立即進行自我監察"}
                  </Button>
                </div>
              </RBackground>
              <Questionnaire
                visible={Fthis.state.QuestionnaireDisplayMoobile}
                mobile={true}
                onBooking={() => {
                  Fthis.setState({
                    BookingDisplay: true,
                  });
                }}
                onClose={() => {
                  Fthis.setState({
                    QuestionnaireDisplayMoobile: false,
                  });
                }}
              />

              <RBackground
                id={"Video_mobile"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={5135}
                // url={"https://z3.ax1x.com/2021/04/12/cBYAJJ.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/mobile_Forxiga_diabetes_documentary2.png"}
                url={"https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/moblie-diabetes-documentary2.png"}
                style={{ fontSize: 500 }}
                disable
              >
                {/* 医生专访 视频1 */}
                <div
                  style={{
                    position: "absolute",
                    top: 1511,
                    left: 410,
                    width: 893,
                    height: 976,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.setState({ vid1Display: "vid1" });
                  }}
                >
                  <div style={{
                    width: "100%",
                    height: 500,
                    backgroundImage: `url(https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/v1.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}>

                  </div>
                </div>
                {/* 医生专访 视频2 */}
                <div
                  style={{
                    position: "absolute",
                    top: 2560,
                    left: 410,
                    width: 898,
                    height: 976,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.setState({ vid1Display: "vid2" });
                  }}
                >
                  <div style={{
                    width: "100%",
                    height: 500,
                    backgroundImage: `url(https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/v2.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }} />
                </div>

                {/* 医生专访 视频3 */}
                <div
                  style={{
                    position: "absolute",
                    top: 3608,
                    left: 410,
                    width: 896,
                    height: 976,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Fthis.setState({ vid1Display: "vid3" });
                  }}
                >
                  <div style={{
                    width: "100%",
                    height: 500,
                    backgroundImage: `url(https://login.az.yubis.net/sites/default/files/imce_upload/admin/design-files/Forxiga/v3.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }} />
                </div>

                {/* b 医生专访code hk */}
                <div
                  style={{ width: "100%", height: 0, }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "4636px",
                      fontSize: 14,
                      width: "100%",
                      textAlign: "center",
                      background2: "#71b95375",
                      color: "white"
                    }}
                  >
                    HK-5624 05/07/2021
                  </div>
                </div>
                {/* e 医生专访code hk */}

                {/* 糖尿纪录片 立即检测按钮 */}
                <div
                  style={{
                    position: "absolute",
                    top: 4728,
                    left: 326,
                    width: 1055,
                    height: 257,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire_mobile}
                />
                {/* 单独的视频 */}
                <div
                  style={{
                    position: "absolute",
                    top: 562,
                    left: 308,
                    width: 1096,
                    height: 618,
                    backgroundRepeat: "no-repeat",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={this.displayVid1_mobile}
                >
                  <VideoOverlay
                    // uid={"k4WToeAKUgaSHrwOIc8"} 
                    video={vids.master}
                  />

                </div>
              </RBackground>
              <RBackground
                id={"HeartFailure_mobile"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={2690}
                url={"https://login.az.yubis.net/sites/default/files/2022-04/cr7OhV_edited_ok.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/2022-04/cr7OhV_edited.png"}
                // url={"https://z3.ax1x.com/2021/04/13/cr7OhV.png"}
                style={{ fontSize: 500 }}
              ></RBackground>
              <RBackground
                id={"complication"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={5627}
                url={"https://z3.ax1x.com/2021/04/13/crT9v4.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 5232,
                    left: 333,
                    width: 1052,
                    height: 264,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire_mobile}
                />
              </RBackground>
              <RBackground
                id={"Data2_mobile"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={2311}
                url={"https://z3.ax1x.com/2021/04/13/crqEOe.png"}
                style={{ fontSize: 500 }}
              ></RBackground>
              <RBackground
                id={"How_mobile"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={4472}
                url={"https://login.az.yubis.net/sites/default/files/2022-04/forxigaBK-mob-8_edited_ok.png"}
                // url={"https://z3.ax1x.com/2021/04/13/crxPfA.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/2022-04/forxigaBK-mob-8_edited.png"}
                // url={"https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/forxigaBK-mob-8-2.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 4033,
                    left: 333,
                    width: 1052,
                    height: 264,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                  onClick={this.displayQuestionnaire_mobile}
                />
              </RBackground>
              {/* <RBackground
                id={"Download_mobile"}
                mainRef={this.state.ref_mobile}
                width={1700}
                height={2308}
                url={"https://z3.ax1x.com/2021/04/12/cBsjW6.png"}
                style={{ fontSize: 500 }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 1896,
                    left: 473,
                    width: 760,
                    height: 223,
                    background2: "#71b95375",
                    cursor: "pointer",
                  }}
                // onClick={() => {
                //   this.即将推出("快將推出");
                // }}
                >
                  <a
                    href="https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/Forxiga_Booklet_210712003.pdf"
                    download="Forxiga_Booklet_210712003.pdf"
                    style={{ display: "block", width: "100%", height: "100%" }}
                  ></a>
                </div>
              </RBackground> */}

              {/* {inner} */}
            </div>
          </Col>
        </Row>

        <Row>
          {/* b 立即下载 code */}
          <Col
            md={Layout_md_PC}
            span={Layout_span_PC}
            style={{ width: "100%" }}
          >
            <div
              style={{
                position: "absolute",
                top: "-36px",
                fontSize: 14,
                width: "100%",
                textAlign: "center",
                background2: "#71b95375",
              }}
            >
              HK-6010 04/10/2021
            </div>
          </Col>
          <Col md={Layout_md_mobile} span={Layout_span_mobile}>
            <div
              style={{
                position: "absolute",
                top: "-11.056vw",
                fontSize: 14,
                width: "100%",
                textAlign: "center",
                background2: "#71b95375",
              }}
            >
              HK-6010 04/10/2021
            </div>
          </Col>
          {/* e 立即下载 code */}

          <Col
            span={24}
            style={{
              fontSize: 15,
              padding: 20,
              paddingLeft: "10%",
              paddingRight: "10%",
              zoom: 1,
            }}
            className={"reference"}
          >
            {/* <div style={{ 
                 width:"100%",
                 
                  }}> */}
            <b>{"參考資料："}</b>
            <br />
            <p>
              <p>
                {`1. Centre for Health Protection. DOH. HKSARG. URL: `}
                <u>
                  <a href="https://www.chp.gov.hk/en/healthtopics/content/25/59.html">
                    {
                      "https://www.chp.gov.hk/en/healthtopics/content/25/59.html"
                    }
                  </a>
                </u>
                {"."}
              </p>
              <p>{" Retrieved on 31 Dec 2020."}</p>
              <p>
                {` 2. Ng JHY, et al. Endocrinol Metab                (Seoul). 2018 Mar; 33(1): 17–32.`}
              </p>
              <p>
                {`  3. Maqbool M, et al. Semin Nephrol                2018;38:217–232`}
              </p>
              <p>
                {`  4. Cardiovascular diseases (CVDs). (2017,                May 17). WHO.`}
                <u>
                  <a href="https://www.who.int/en/news-room/fact-sheets/detail/cardiovascular-diseases-(cvds)">
                    {
                      "https://www.who.int/en/news-room/fact-sheets/detail/cardiovascular-diseases-(cvds)"
                    }
                  </a>
                </u>
              </p>
              <p>
                {`  5. Dhingra R, et al. Heart                Fail Clin 2012; 8(1): 125–133.`}
              </p>
              <p>
                {`  6. Faden G, et al. Diabetes Res Clin Pract                2013; 101(3): 309–316.`}
              </p>
              <p>
                7.
                {`    Echouffo-Tcheugui, J. B., Erqou,
                S., Butler, J., Yancy, C. W., &amp; Fonarow, G. C. (2016). Assessing the Risk of Progression From
                Asymptomatic Left Ventricular Dysfunction to Overt Heart Failure. JACC: Heart
                Failure, 4(4), 237–248. `}
                <u>
                  <a href="https://doi.org/10.1016/j.jchf.2015.09.015">
                    {"https://doi.org/10.1016/j.jchf.2015.09.015"}
                  </a>
                </u>
              </p>
              <p>
                {`8. Pazos-López                P, et al. Vasc                Health Risk Manag.                2011; 7: 237–254.`}
              </p>
              <p>
                {` 9. NHS.URL: `}
                <u>
                  <a href="https://www.nhs.uk/conditions/heart-failure/symptoms/">
                    https://www.nhs.uk/conditions/heart-failure/symptoms/
                  </a>
                </u>
                {`. Retrieved on 31 Dec 2020.`}
              </p>
              <p>
                {`10. Symptoms and causes - Mayo Clinic.                (2021). Mayo Clinic.`}
                <u>
                  {" "}
                  <a href="https://www.mayoclinic.org/diseases-conditions/diabetic-nephropathy/symptoms-causes/syc-20354556?p=1">
                    {
                      "https://www.mayoclinic.org/diseases-conditions/diabetic-nephropathy/symptoms-causes/syc-20354556?p=1"
                    }
                  </a>
                </u>
              </p>
              <p>
                1{`1. World Health Organization. URL: `}
                <u>
                  <a href="https://www.who.int/news-room/fact-sheets/detail/diabetes">
                    {
                      "https://www.who.int/news-room/fact-sheets/detail/diabetes"
                    }
                  </a>
                </u>
                {"."}
                {`Retrieved on 31 Dec 2020.`}
              </p>
              <p>
                {` 12. Ling                W, Huang Y, Huang YM, et al. Cardiovasc Diabetol. 2020 Oct                20;19(1):182.`}
              </p>
              <p>
                {` 13. InternationalDiabetes Federation. URL:`}{" "}
                <u>
                  <a href="https://www.idf.org/our-activities/care-prevention/diabetes-and-the-kidney.html">
                    https://www.idf.org/our-activities/care-prevention/diabetes-and-the-kidney.html
                  </a>
                </u>
                {". Retrieved on 20 Dec 2020."}
              </p>
              <p>
                {`  14.台灣腎臟醫學會. URL: `}
                <u>
                  <a href="http://www.ckd-tsn.org.tw/knowledge/index.php?tab_name=chronic">
                    http://www.ckd-tsn.org.tw/knowledge/index.php?tab_name=chronic
                  </a>
                </u>
                {`.Retrieved on 21 Jan 2021.`}
              </p>
              <p>
                {`  15. Urine Albumin-to-Creatinine Ratio (UACR) In Evaluating Patients with Diabetes for Kidney Disease. (2010). NIDDK.`}
              </p>
              <p>
                {`16. National Kidney Foundation. URL: `}
                <u>
                  <a href="https://www.kidney.org/content/kidney-failure-risk-factor-urine-albumin-to-creatinine-ration-uacr">
                    https://www.kidney.org/content/kidney-failure-risk-factor-urine-albumin-to-creatinine-ration-uacr
                  </a>
                </u>
                {`.Retrieved on 21 Jan 2021.`}
              </p>
              {`17. Ronco P, Rovin B,et al. Kidney Int. 2020 Oct;98(4S):S1-S115.`}
            </p>
            <br />

            {/* {"HK-6471 17/12/2021"} */}
            {"HK-7119 13/05/2022"}
            <p>
              <br />
            </p>
          </Col>
        </Row>
      </div>
    );
  }

  componentWillUnmount() {
    // 移除监听
    window.removeEventListener('message', this.setIframeHandler, false)
  }
}
export default App;
