import React, { PureComponent } from "react";
import { createGUID } from './requester';
import { Row, Col, Modal, Radio, Button } from 'antd';
import { RBackground } from './requester';

class Questionnaire extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.ref_mobile = React.createRef();
		this.GUID = createGUID();
		this.state = {
			// page2:true
			data: {}
		};
		this.table = [
			{
				title: "整體狀況",
				child: [{
					title: "你的血糖控制良好嗎?",
					options: ["良好", "不良好"],
				}, {
					title: "體重達到理想水平嗎?",
					options: ["是", "不是"],
				}, {
					title: "你的血壓正常嗎?",
					options: ["正常", "不正常"],
				}]
			},
			{
				title: "心臟功能情況",
				child: [{
					title: "血糖超標會影響心臟功能，你有監察你的心臟功能嗎?",
					options: ["有", "没有"],
				}, {
					title: "最近有沒有腳部水腫的情況?",
					options: ["没有", "有"],
				}, {
					title: "最近有沒有呼吸困難、氣喘或經常咳嗽的情況?",
					options: ["没有", "有"],
				}, {
					title: "最近有沒有容易感到乏力或頭暈?",
					options: ["没有", "有"],
				}, {
					title: "最近一星期體重有沒有突然上升?",
					options: ["没有", "有"],
				}, {
					title: "最近有沒有感到胸口痛?",
					options: ["没有", "有"],
				},]
			},
			{
				title: "腎功能情況",
				child: [{
					title: "血糖超標會影響腎功能，你有監察你的腎臟健康嗎?",
					options: ["有", "没有"],
				}, {
					title: "最近一年有沒有進行過腎功能評估(eGFR)?",
					options: ["有", "没有"],
				}, {
					title: "最近一年有沒有進行過微蛋白尿檢查?",
					options: ["有", "没有"],
				}]
			},
		]
	}
	componentDidMount() {
		let Fthis = this;
		this.setState({
			ref: this.ref,
			ref_mobile: this.ref_mobile,

		})
		// setTimeout(() => {
		// 	Fthis.setState({
		// 		page2: true
		// 	})
		// }, 100);
	}
	render() {
		let Fthis = this;
		if (this.props.visible != this.state.visible) {
			Fthis.setState({ visible: Fthis.props.visible })
		}
		if (this.props.mobile) { }
		return (
			<>
				<Overlay2
					visible={this.state.page2}
					onBooking={this.props.onBooking}
					onClose={() => {
						Fthis.setState({
							page2: false
						})
						if (typeof (Fthis.props.onClose) == "function") {
							Fthis.props.onClose()
						}

					}}
				/>
				<Modal
					title={null}
					footer={null}
					visible={this.state.page2 !== true && this.props.visible}
					// visible={false}
					width={1000}
					style={{
						maxWidth: "95%",
						// minWidth: 450,
					}}
					onCancel={this.props.onClose}
					forceRender
				>
					<div ref={this.ref}>
						<Row>
							<Col md={24} span={0}>
								<RBackground
									mainRef={this.state.ref}
									visible={this.state.visible}
									width={1172}
									height={293}
									url={"https://z3.ax1x.com/2021/04/08/cJOMeU.png"}
									style={{ fontSize: 50 }}>
									<div style={{ position: "absolute", top: 55, left: 430, color: "#464646", fontWeight: 500, fontSize: 32, lineHeight: "58px", textAlign: "center" }}>
										{"自我監察心腎健康清單"}
									</div>
								</RBackground>
							</Col>
							<Col md={0} span={24}>
								<RBackground
									mainRef={this.state.ref}
									visible={this.state.visible}
									width={1101}
									height={633}
									url={"https://z3.ax1x.com/2021/04/12/cB6CcT.png"}
									style={{ fontSize: 50 }}>
								</RBackground>
							</Col>
						</Row>
						<div style={{ paddingLeft: 17, paddingRight: 17, fontWeight: 200 }}>
							{Array.from(this.table).map(item => {
								return <>
									<div style={{ height: 65, width: "100%", background: "#E06AB4", lineHeight: "60px", paddingLeft: 25, fontSize: 32, color: "white" }}>
										{item.title}
									</div>
									<Row style={{ fontSize: 22, lineHeight: "69px", color: "black" }}>
										{Array.from(item.child).map((childItem, id) => {
											let background = "#F8F8F8"
											if (id % 2 != 0) {
												background = "#F0F0F0"
											}
											return <>
												<Col lg={16} span={24} style={{ paddingLeft: 25, paddingRight: 25, height2: 73, background }}>
													<div style={{ lineHeight: "26px", display: "inline-block", verticalAlign: "middle" }}>
														{childItem.title}
													</div>
												</Col>
												<Col lg={8} span={24} style={{ paddingLeft: 25, height2: 73, background, userSelect: "none", filter: "hue-rotate(99deg)" }}>
													<Radio.Group
														size={"large"}
														style={{ zoom: 1.5 }}
														onChange={(event) => {
															Fthis.setState({
																data: { ...Fthis.state.data, [childItem.title]: event.target.value }
															})
														}}
														value={this.state.data[childItem.title]}
													>
														<Radio value={childItem.options[0]}>{childItem.options[0]}</Radio>
														<Radio value={childItem.options[1]}><span style={{ color: "#2232f5" }}>{childItem.options[1]}</span></Radio>
													</Radio.Group>
												</Col>

											</>

										})}
									</Row>
								</>
							})}

						</div>
						<div style={{ textAlign: "center", width: "100%", marginTop: 24, paddingBottom: 24 }}>
							<Button
								type="primary"
								disabled={Object.keys(this.state.data).length < 12}
								style={{
									filter: "hue-rotate(99deg)",
									//  position:"relative",left:345,top:0,
									width: 300, height: 72,
									//  background: "#DB2780",
									//   borderColor: "transparent", 
									fontSize: 26
								}} onClick={() => {
									Fthis.setState({
										page2: true,
										ts: performance.now(),
									})
									// setTimeout(() => {
									// 	alert(String(Fthis.state.page2))
									// }, 1000);
								}}>
								{"立即睇結果"}
							</Button>
						</div>


					</div>
				</Modal>

			</>
		);
	}
	componentWillUnmount() {

	}
}
class Overlay2 extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.GUID = createGUID();
		this.state = {
			picBlobUrl: undefined,
		};
	}
	componentDidMount() {
		let Fthis = this;
		this.setState({
			ref: this.ref,
		})
		setTimeout(() => {
			Fthis.setState({
				ts: performance.now(),
			})
		}, 1000);

	}
	render() {
		let Fthis = this;
		if (this.props.visible != this.state.visible) {
			Fthis.setState({ visible: Fthis.props.visible })
		}
		return (
			<Modal
				title={null}
				footer={null}
				visible={!this.state.page3 && this.props.visible}
				width={1000}
				style={{
					maxWidth: "95%",
					zIndex: 150000000
				}}
				onCancel={this.props.onClose}
				forceRender

			>
				<div ref={this.ref} id="ref" style={{ background: "#E7B000" }}>
					<Row>
						<Col md={24} span={0}>
							<RBackground
								mainRef={this.state.ref}
								visible={this.state.visible}
								dev={true}
								width={1172}
								height={976}
								url={"https://z3.ax1x.com/2021/04/08/cYlK8H.png"}
								style={{
									fontSize: 50,
									position: "relative",
									top: -99
								}}>
								<div style={{ position: "absolute", top: 303, left: 34, color: "#464646", fontWeight: 600, fontSize: 28, lineHeight: "47px" }}>
									{"你的選擇若多於一個"}<span style={{ color: "#e52387" }}>{"紅色答案"}</span>{"，都表示閣下需密切留意"}<br />
									{"併發症風險，"}<span style={{ color: "#e52387" }}>{"建議應主動向醫生查詢心腎狀況"}</span>{"、或"}<br />
									{"進行 相關評估，避免病情進一步惡化!"}<br />
								</div>
								<div style={{ position: "absolute", top: 644, left: 35, color: "#fff", fontWeight: 600, fontSize: 40 }}>
									{"問問醫生清單"}
								</div>
								<div style={{ position: "absolute", top: 738, left: 35, color: "#fff", fontWeight: 400, fontSize: 24, lineHeight: "43px" }}>
									{"就診時可考慮查詢以下問題，以更好掌握心腎狀況及治療方案"}
									<br />
									<br />
									<div style={{ lineHeight: "50px", fontSize: 19, lineHeight: "26px" }}>
										{"1. 我的病情控制如何? 有需要改善嗎?"}
										<br />
										{"2. 我最近出現了___ (例如水腫、體重上升) 的病徵，請問是血糖超標、定抑或是心臟或腎臟問題引起呢?"}
										<br />
										{"3. 聽說血糖管理不善會影響心臟及腎臟功能，請問我的心臟及腎功能的狀況如何？"}
										<br />

									</div>
									<div style={{ lineHeight: "53px", fontSize: 18 }}>
										{"(以上僅供參考，病人應就個人情況或意願作選擇或修改)"}
									</div>
								</div>
								<div style={{ position: "absolute", top: 964, right: 60, textAlign: "right", }}>

									<Button loading={this.state.loading} type="primary" size={"large"} style={{
										// height: "unset",
										zoom: 2,
										right: 0,
										position: "absolute"
									}} onClick={() => {
										// Fthis.setState({ loading: true })
										this.props.onBooking()
										this.props.onClose()
										// window.open("https://docs.google.com/forms/d/e/1FAIpQLSe80KhptNXbeZoYhVv4PW6muQsnJ5iDJorPsQZNQuJI7ic4hg/viewform")
									}}>
										{"立即行動參加康心摯友,糖尿腎風險檢測日"}

									</Button>
								</div>


							</RBackground>
						</Col>
						<Col md={0} span={24}>
							<RBackground
								mainRef={this.state.ref}
								visible={this.state.visible}
								dev={true}
								width={1101}
								height={2484}
								url={"https://z3.ax1x.com/2021/04/12/cBLtgS.png"}
								style={{
									fontSize: 50,
									position: "relative",
									// top: -99
								}}>
								<div style={{ position: "absolute", top: 1603, right: 43, textAlign: "right", zoom: 1.5 }}>

									<Button loading={this.state.loading} type="primary" size={"large"} style={{
										// height: "unset",
										zoom: 2,
										right: 0,
										position: "absolute"
									}} onClick={() => {
										// Fthis.setState({ loading: true })
										this.props.onBooking()
										this.props.onClose()
										// window.open("https://docs.google.com/forms/d/e/1FAIpQLSe80KhptNXbeZoYhVv4PW6muQsnJ5iDJorPsQZNQuJI7ic4hg/viewform")
									}}>
										{"立即行動參加康心摯友,糖尿腎風險檢測日"}

									</Button>
								</div>
							</RBackground>
							<div style={{ height: 30, background: "#E7B000" }}></div>
						</Col>
					</Row>

				</div>

			</Modal>
		);
	}
	componentWillUnmount() {

	}
}

export { Questionnaire };
