import React, { PureComponent } from "react";
import { createGUID } from './requester';
import { Row, Col, Modal, Radio } from 'antd';
import { RBackground } from './requester';
import { Typography } from 'antd';
import { Form, Input, Button, Checkbox } from 'antd';
import { Select } from 'antd';
// import { loadReCaptcha } from 'react-recaptcha-v3'
// import { ReCaptcha } from 'react-recaptcha-v3'
import ReCAPTCHA from "react-google-recaptcha";
import { notification, Space } from 'antd';
import { message } from 'antd';
// import { Radio } from 'antd';
import { Table } from 'antd';
import { Drawer } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const recaptchaRef = React.createRef();

// const debug = true
const debug = false
const { Option } = Select;
const { Title } = Typography;

let ReCAPTCHAKey = ""
let tableData = {}
let fetchData = function (accesskey) {
	return new Promise(function (resolve, reject) {
		// let api = "https://dm-screening.azhealthclub.com.hk/dataview?" + accesskey
		let api = `https://azhealthclub.com.hk/dm-screening/dataview?${accesskey}?${new Date().valueOf()}`
		if (document.location.port != "" && localStorage.dev == "true") {
			api = `http://127.0.0.1:18051/dm-screening/dataview?${accesskey}?${new Date().valueOf()}`
			// api = "http://127.0.0.1:18051/dm-screening/dataview?" + accesskey
		}
		let xhr = new XMLHttpRequest()
		xhr.open("GET", api, true)
		xhr.send()
		xhr.onreadystatechange = () => {
			if (xhr.readyState == XMLHttpRequest.DONE) {
				if (xhr.status >= 200 && xhr.status <= 300) {
					try {
						let data = JSON.parse(xhr.responseText);
						console.log("DATA", data);
						resolve(data)
					} catch (error) {
						reject("data_ERR")
					}

				} else {
					reject("NET_ERR")
				}
			}
		}
	})
}
let cancelRequest = function (accesskey, key) {
	return new Promise(function (resolve, reject) {
		let api = "https://azhealthclub.com.hk/dm-screening/cancelRequest?" + accesskey + "&" + key
		if (document.location.port != "" && localStorage.dev == "true") {
			api = "http://127.0.0.1:18051/dm-screening/cancelRequest?" + accesskey + "&" + key
		}
		let xhr = new XMLHttpRequest()
		xhr.open("GET", api, true)
		xhr.send()
		xhr.onreadystatechange = () => {
			if (xhr.readyState == XMLHttpRequest.DONE) {
				if (xhr.status >= 200 && xhr.status <= 300) {
					try {
						let data = JSON.parse(xhr.responseText);
						console.log("DATA", data);
						resolve(data)
					} catch (error) {
						reject("data_ERR")
					}

				} else {
					reject("NET_ERR")
				}
			}
		}
	})
}

class Control extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.recaptchaRef = React.createRef();
		this.GUID = createGUID();
		this.state = {
			picBlobUrl: undefined,
		};
		// const recaptchaRef = React.useRef();
	}
	componentDidMount() {
		let Fthis = this;
		this.setState({
			ref: this.ref,
			dataKey: "",
			radioOption: "raw",
		})

		// fetchData().then(function (response) {
		// 	console.log("response", response);
		// 	Fthis.setState({
		// 		regions: response.regionsObj,
		// 		regionNames: response.regionsArr,
		// 	})
		// }).catch(function (error) {

		// }).then(function (response) {

		// })




		Fthis.setState({
			dataKey: localStorage.forxiga_343_DataKey
		})
	}
	render() {
		let Fthis = this;
		if (this.props.visible != this.state.visible) {
			Fthis.setState({ visible: Fthis.props.visible })
		}

		const dataSource = [
			// {
			// 	key: '1',
			// 	name: '胡彦斌',
			// 	age: 32,
			// 	address: '西湖区湖底公园1号',
			// },
			// {
			// 	key: '2',
			// 	name: '胡彦祖',
			// 	age: 42,
			// 	address: '西湖区湖底公园1号',
			// },
		];
		// console.log("render")
		if (
			typeof (this.state.sqlDATA) == "object" &&
			Array.isArray(this.state.sqlDATA.locationsList) &&
			Array.isArray(this.state.sqlDATA.ordersList) &&
			Array.isArray(this.state.sqlDATA.timeList)
		) {

			let locationsList = {}
			for (let item of this.state.sqlDATA.locationsList) {
				locationsList[item.Id] = item.Name
			}
			let timeList = {}
			for (let item of this.state.sqlDATA.timeList) {
				try {
					timeList[item.Id] = {
						region: locationsList[item.regionId],
						startTime: item.startTime
					}
				} catch (error) {
					console.error(error)
				}
			}

			for (let item of this.state.sqlDATA.ordersList) {
				try {
					let thisLocation = "不存在!"
					let thisTime = "不存在!"
					let thisISOTime = new Date().toISOString()
					try {
						thisLocation = timeList[item.TimeId].region
						thisTime = new Date(timeList[item.TimeId].startTime).toLocaleString()
						thisISOTime = new Date(timeList[item.TimeId].startTime).toISOString()
					} catch (error) {

					}
					let obj = {
						Id: String(item.Id),
						canceled: Number(item.canceled),
						location: thisLocation,
						time: thisTime,
						ISOTime: thisISOTime,
						email: item.email,
						phoneNumber: item.phoneNumber,
						username: item.username || "-",
						check1: "-",
						check2: "-",
						registrationDate: "-",
						remoteIp: "-",
					}
					if (Fthis.state.radioOption == "raw") {
						try {
							let rawData = JSON.parse(decodeURI(window.atob(item.dataRaw)))
							obj.phone = rawData.phone
							obj.email = rawData.email
							obj.username = rawData.username
							obj.check1 = rawData.check1
							obj.check2 = rawData.check2
							obj.registrationDate = rawData.registrationDate
							obj.remoteIp = rawData.ipForwarded || rawData.remoteIp
						} catch (error) {
							console.error(error)
						}
					}
					dataSource.push(obj)
				} catch (error) {
					console.error("ERR-32", item, timeList, error)
				}

			}


		}


		// console.log("dataSource", dataSource)
		const columns = [
			{
				title: 'ID',
				dataIndex: 'Id',
				key: 'Id',
				defaultSortOrder: 'descend',
				sorter: (a, b) => a.Id - b.Id,
			},
			{
				title: 'location',
				dataIndex: 'location',
				key: 'location',
				filters: (() => {
					if (typeof (this.state.sqlDATA) == "object" &&
						Array.isArray(this.state.sqlDATA.locationsList)) {
						let arr = []
						for (let item of this.state.sqlDATA.locationsList) {
							arr.push({ text: item.Name, value: item.Name, })
						}
						return arr
					}
					return []
				})(),
				onFilter: (value, record) => record.location === value,
			},
			{
				title: 'time',
				dataIndex: 'time',
				key: 'time',
				filters: (() => {
					if (typeof (this.state.sqlDATA) == "object" &&
						Array.isArray(this.state.sqlDATA.timeList)) {
						let arr = []
						for (let item of this.state.sqlDATA.timeList) {
							arr.push({ text: new Date(item.startTime).toLocaleString(), value: item.startTime, })
						}
						return arr
					}
					return []
				})(),
				onFilter: (value, record) => new Date(record.ISOTime).valueOf() === new Date(value).valueOf(),			 
			},

			{
				title: 'email(# as @)',
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: 'phoneNumber',
				dataIndex: 'phoneNumber',
				key: 'phoneNumber',
			},
			{
				title: 'name',
				dataIndex: 'username',
				key: 'username',
			},
			{
				title: 'registrationDate',
				dataIndex: 'registrationDate',
				key: 'registrationDate',
				render: (value) => {
					try {
						if (new Date(value).valueOf() > 0) {
							return new Date(value).toLocaleString()
						}
					} catch (error) { }
					return "-"
				}
			},
			{
				title: 'remoteIp',
				dataIndex: 'remoteIp',
				key: 'remoteIp',
			},
			{
				title: 'action',
				dataIndex: 'canceled',
				key: 'canceled',
				render: (value, item) => {
					console.log("value", value, item)
					if (value) {
						return <Button disabled>{"canceled"}</Button>
					} else {
						return <Button onClick={() => {
							Modal.confirm({
								title: 'Do you Want to cancel this request?',
								icon: <ExclamationCircleOutlined />,
								content: 'Action cannot  be undo!',
								onOk() {
									cancelRequest(Fthis.state.dataKey, item.Id).then(() => {
										fetchData(Fthis.state.dataKey).then(function (response) {
											console.log("response", response);
											Fthis.setState({
												sqlDATA: response,
												ts: performance.now(),
											})
										}).catch(function (error) {
											message.error('ERROR', 3);
										}).then(function (response) {

										})
									})
								},
								onCancel() {
								},
							});
						}}>{"cancel"}</Button>
					}

				}
			},

			// {
			// 	title: 'name',
			// 	dataIndex: 'username',
			// 	key: 'username',
			// },
			// {
			// 	title: 'extra',
			// 	dataIndex: 'Id',
			// 	key: 'extra',
			// 	render: (data, line) => {
			// 		return <div>
			// 			<Button type="primary">
			// 				{"Primary Button"}
			// 			</Button>
			// 		</div>
			// 	}
			// },
		];
		return (
			<Drawer
				title="DataViewer"
				placement={"top"}
				closable={false}
				// onClose={this.onClose}
				visible={this.props.visible}
				// key={placement}
				height={"100%"}
			>
				<Row gutter={16}>
					<Col span={20}>
						{"V 31"}
						<Input.Password
							placeholder="AccessKey"
							value={this.state.dataKey}
							onChange={(value) => {
								// console.log("value", value.target.value)
								localStorage.forxiga_343_DataKey = value.target.value
								Fthis.setState({ dataKey: value.target.value })
							}} />
					</Col>
					<Col span={4}>
						<Button type="primary" style={{ width: "100%" }} onClick={() => {
							fetchData(Fthis.state.dataKey).then(function (response) {
								console.log("response", response);
								Fthis.setState({
									sqlDATA: response,
									ts: performance.now(),
								})
							}).catch(function (error) {
								message.error('ERROR', 3);
							}).then(function (response) {

							})
						}}>{"Send Request"}</Button>
					</Col>
				</Row>
				<Radio.Group
					options={[
						{ label: 'safe', value: 'safe' },
						{ label: 'raw', value: 'raw' },
					]}
					onChange={(event) => {
						this.setState({
							radioOption: event.target.value,
						});
					}}
					value={this.state.radioOption}
					optionType="button"
					buttonStyle="solid"
				/>
				<Table dataSource={dataSource} columns={columns} />

			</Drawer>
		);
	}
	componentWillUnmount() {

	}
}

export { Control };
