import React from "react";

const AppPage = (props) => {

	return (

		<iframe src="https://diabeticcharity.azhealthclub.com.hk/photo"
			className="photo-iframe"
			id="gallery"
			style={{
				...props.photoStyle,
			}}
			title="糖尿健康抗疫行动"
			frameBorder="0"></iframe>
	)

}

export default AppPage