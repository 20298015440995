export const vids = {
	master: {
		title: "YouTube video player",
		frameBorder: " 0",
		src: 'https://www.youtube.com/embed/tV8luE_XJZ0',
		allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
		allowFullScreen: true
	},
	Forxiga_Dr_Leung_final09: {
		title: "YouTube video player",
		frameBorder: "0",
		type: "text/html",
		src: 'https://www.dailymotion.com/embed/video/k7yy5wxY02XRzawYwQD?autoplay=1',
		allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
		allowFullScreen: true
	},
	Forxiga_Dr_Lo_final09: {
		title: "YouTube video player",
		type: "text/html",
		src: 'https://www.dailymotion.com/embed/video/k4QV5fEw7E1WlOwYwUE?autoplay=1',
		allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
		allowFullScreen: true
	},
	Forxiga_Prof_Tang_final09:
	{
		title: "YouTube video player",
		frameBorder: " 0",
		type: "text/html",
		src: 'https://www.dailymotion.com/embed/video/k2VmlVaQVN3qdXwYwYx?autoplay=1',
		allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
		allowFullScreen: true
	},
}