import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import "./global.css";

// if (!!!document.getElementById('antd-css-import')) {
//   let head = document.head
//   let link = document.createElement("link")
//   link.id = 'antd-css-import'
//   link.rel = "stylesheet"
//   link.type = "text/css"
//   link.href = 'https://cdn.jsdelivr.net/npm/antd@3.26.20/dist/antd.min.css';
//   head.appendChild(link)
// }

if (!!!document.getElementById('googleapis-fonts-import')) {
  let head = document.head
  let style = document.createElement("style")
  style.id = 'googleapis-fonts-import'
  style.innerHTML = `@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&display=swap');`
  head.appendChild(style)
}
if (!!!document.getElementById('antd-css-v4-import')) {
  let head = document.head
  let link = document.createElement("link")
  link.id = 'antd-css-v4-import'
  link.rel = "stylesheet"
  link.type = "text/css"
  // link.href = 'https://cdn.jsdelivr.net/npm/antd@3.26.20/dist/antd.min.css';
  // link.href = 'https://cdn.jsdelivr.net/npm/antd@4.15.2/dist/antd.min.css';
  link.href = 'https://cdn.jsdelivr.net/npm/antd@4.15.1/dist/antd.min.css';
  head.appendChild(link)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
