import React, { PureComponent } from "react";
import { Modal } from 'antd';
class UploadPhoto extends PureComponent {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			renderStyle: {
				...props.photoStyle
			}
		}
	}

	// setIframe (event) {
	// 	console.log('-----', event);
	// }
	// componentDidMount () {
	// 	this.setIframeHandler = this.setIframe.bind(this);
	// 	window.addEventListener('message', this.setIframeHandler, false);
	// }
	componentWillReceiveProps (nextProps) {
		this.setState({
			visible: nextProps.visible,
			renderStyle: nextProps.photoStyle
		})
	}
	render () {
		return (<Modal visible={this.state.visible} width={1000}
			closable={true}
			destroyOnClose={true}
			onCancel={this.props.onClose}
			footer={null}
			wrapClassName="custom-iframe-modal"
			style={{
				maxWidth: "95%",
				height: "800px",
				...this.state.renderStyle
			}}>
			{/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
			<iframe src="https://diabeticcharity.azhealthclub.com.hk"
				className="photo-iframe-form"
				frameBorder="0"></iframe>
		</Modal>)
	}

}

export default UploadPhoto