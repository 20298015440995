import React, { PureComponent } from "react";
import { createGUID } from "./requester";
import { Row, Col, Modal, Radio } from "antd";
import { RBackground } from "./requester";
import { Typography } from "antd";
import { Form, Input, Button, Checkbox } from "antd";
import { Select } from "antd";
// import { loadReCaptcha } from 'react-recaptcha-v3'
// import { ReCaptcha } from 'react-recaptcha-v3'
import ReCAPTCHA from "react-google-recaptcha";
import { notification, Space } from "antd";
// import { delete } from "request";
import { currentVersion } from "./version"
const recaptchaRef = React.createRef();

// const debug = true
const debug = false;
const { Option } = Select;
const { Title } = Typography;

let ReCAPTCHAKey = "";
let tableData = {};
let fetchData = function () {
  return new Promise(function (resolve, reject) {
    let api = `https://azhealthclub.com.hk/dm-screening/fetch?${new Date().valueOf()}`;
    if (document.location.port != "") {
      if (localStorage.local == "true") {
        api = `http://127.0.0.1:18051/dm-screening/fetch?${new Date().valueOf()}`;
      } else if (localStorage.dev == "true") {
        api = `http://18.167.105.217:18051/dm-screening/fetch?${new Date().valueOf()}`;
      }
    }


    let xhr = new XMLHttpRequest();
    xhr.open("GET", api, true);
    xhr.send();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status <= 300) {
          try {
            let data = JSON.parse(xhr.responseText);
            console.log("DATA", data);
            let regionsObj = {};
            let regionsArr = [];
            for (let orgionItem of data) {
              for (let aid in orgionItem["timeList"]) {

                if (parseInt(aid) == aid) {
                  let item = orgionItem["timeList"][aid];
                  // if (parseInt(item.limits) <= 0) {
                  // //   console.warn("item.limits",item.limits)
                  // //   // continue
                  // }

                  item.id = item.Id;
                  item.region = orgionItem.Name;
                  item.begin = item.startTime;
                  item.end = item.endTime;
                  item.regEnd = item.regEndTime;
                  item.left = parseInt(item.limits) - parseInt(item.count) || 0;
                  orgionItem["timeList"][aid] = item;
                }
              }
              regionsObj[orgionItem.Name] = orgionItem["timeList"];

              regionsArr.push({
                name: orgionItem.Name,
                id: orgionItem.Id,
              });
            }
            console.debug("regionsObj", regionsObj);
            resolve({
              regionsObj,
              regionsArr,
            });
          } catch (error) {
            console.error(error)
            reject("data_ERR");
          }
        } else {
          reject("NET_ERR");
        }
      }
    };
  });
};

// let verifyCallback = (recaptchaToken) => {
// 	// Here you will get the final recaptchaToken!!!
// 	console.log(recaptchaToken, "<= your recaptcha token")
// }

// let updateToken = () => {
// 	// you will get a new token in verifyCallback
// 	this.recaptcha.execute();
// }
class Booking extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.recaptchaRef = React.createRef();
    this.GUID = createGUID();
    this.state = {
      picBlobUrl: undefined,
    };
  }
  componentDidMount() {
    let Fthis = this;
    this.setState({
      ref: this.ref,
    });

    fetchData()
      .then(function (response) {
        for (let oid in response.regionsObj) {
          let hasAvailable = false;
          for (let item of response.regionsObj[oid]) {
            if (new Date(item.startTime).valueOf() > new Date().valueOf()) {
              hasAvailable = true;
            }
          }
          if (!hasAvailable) {
            delete response.regionsObj[oid];
            let newRegionsArr = [];
            console.log("response.oid", oid);
            for (let item of response.regionsArr) {
              if (item.name != oid) {
                newRegionsArr.push(item);
              }
            }
            response.regionsArr = newRegionsArr;
          }
        }
        Fthis.setState({
          regions: response.regionsObj,
          regionNames: response.regionsArr,
        });
      })
      .catch(function (error) { })
      .then(function (response) { });

    this.validate = function (data) {
      tableData = data;
      // window.grecaptcha.execute()
      // recaptchaRef.current.props.grecaptcha.execute()
      console.log("recaptchaRef", recaptchaRef.current);
      if (localStorage.dev == "true") {
        window.grecaptcha.execute();
        return;
      }

      let recaptchaId = 0;
      try {
        recaptchaId = Math.abs(
          parseInt(
            document
              .getElementsByClassName("REACT-FIX-ReCAPTCHA-V2I")[0]
              .getElementsByClassName("grecaptcha-badge")[0]
              .getElementsByTagName("textarea")[0]
              .id.replace("g-recaptcha-response", "")
          )
        );
      } catch (error) {
        console.error(error);
      }
      if (!(recaptchaId > 0)) {
        console.log("ReCAPTCHA 出现问题 ", "如果是测试环境请忽略");
        // notification["error"]({
        // 	message: 'ReCAPTCHA 出现问题 ',
        // 	description: '如果是测试环境请忽略',
        // });
        recaptchaId = 0;
      }
      console.log("ReCAPTCHA id", recaptchaId);
      recaptchaRef.current.props.grecaptcha.execute(recaptchaId);
    };

    this.sendOrder = function (ReCAPTCHAKey) {
      window.loadedOnce = true;
      if (window.sent) {
        return;
      }
      window.sent = true;
      Fthis.setState({ loading: true });

      tableData.recaptchaKey = ReCAPTCHAKey;
      console.log("recaptchaResponse", ReCAPTCHAKey);
      let post = window.btoa(encodeURI(JSON.stringify(tableData)));
      // let api = `https://dm-screening.azhealthclub.com.hk/add?${post}`;
      // let api = `https://azhealthclub.com.hk/dm-screening/add?${post}`;

      let api = `https://azhealthclub.com.hk/dm-screening/add?${post}`;

      if (document.location.port != "") {
        api = `http://127.0.0.1:18051/dm-screening/add?${post}`;
      }

      let xhr = new XMLHttpRequest();
      xhr.open("GET", api, true);
      xhr.send();
      xhr.onreadystatechange = () => {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          if (xhr.status >= 200 && xhr.status <= 300) {
            try {
              let result = JSON.parse(xhr.responseText);
              if (result.result == "done") {
                setTimeout(() => {
                  window.ga('set', 'page', document.location.pathname + "/post");
                  window.ga('send', 'pageview');
                }, 0);
                setTimeout(() => {
                  window.dataLayer.push({ 'event': `foxigaSignEvent` });
                }, 0);

                Fthis.setState({
                  page3: true,
                  loading: false,
                });
              } else if (result.result == "full") {
                Fthis.setState({
                  page3: false,
                  loading: false,
                });
                notification["warning"]({
                  message: "預約已滿",
                  description: "請重新載入頁面後重試",
                });
                setTimeout(() => {
                  document.location.reload();
                }, 2000);
              } else {
                Fthis.setState({
                  page3: false,
                  loading: false,
                });
                notification["warning"]({
                  message: "當前網絡環境存在風險",
                  description: "請更換網絡後重試",
                });
              }
            } catch (error) { }
          } else {
            notification["warning"]({
              message: "當前網絡環境存在風險",
              description: "請更換網絡後重試",
            });
            Fthis.setState({
              loading: false,
            });
          }
        }
      };

      // setTimeout(() => {
      // 	Fthis.setState({
      // 		page3: true,
      // 		loading: false
      // 	})
      // }, 1000);
    };

    this.onDropdownVisibleChange = function (event) {
      let elements = document.getElementsByClassName("ant-modal-wrap");
      for (let element of elements) {
        let overflow = "overlay";
        if (event == true) {
          overflow = "hidden";
        }
        element.style.overflow = overflow;
      }
    };
    // setTimeout(() => {
    // 	Fthis.recaptcha.execute();
    // }, 10000);
  }
  // componentDidUpdate(){
  //   if(this.props.visible){
  //     message.info("活動已經完結",10);

  //   }
  // }
  isVaildTime(beginTime, regEndTime) {
    // console.log("new Date(beginTime).valueOf()", new Date(beginTime).valueOf())
    if (new Date(beginTime).valueOf() < new Date().valueOf()) {
      return false
    }

    // 隐藏特定日期
    if (new Date(beginTime).valueOf() > 1634259600000 && new Date(beginTime).valueOf() < 1634346000000) {
      return false
    }

    // 到达截止日期时隐藏
    if (regEndTime !== null) {
      if (new Date().valueOf() >= new Date(regEndTime).valueOf()) {
        return false
      }
    }
    return true
  }

  formRef = React.createRef();
  handlePhone = (event) => {
    const newValue = String(parseInt(event.target.value) || "").substring(0, 8)
    this.formRef.current.setFieldsValue({ phone: newValue });
  }



  render() {

    // return ""
    let Fthis = this;
    if (this.props.visible != this.state.visible) {
      Fthis.setState({ visible: Fthis.props.visible });
    }

    if (this.props.visible) {
      if (!!!document.getElementById("az-html-fix-import")) {
        let head = document.head;
        let style = document.createElement("style");
        style.id = "az-html-fix-import";
        style.rel = "stylesheet";
        style.innerHTML = `html{
			overflow:hidden !important;
		}`;
        head.appendChild(style);
      }
      try {
        this.onDropdownVisibleChange(false);
      } catch (error) { }
    } else {
      if (!!document.getElementById("az-html-fix-import")) {
        document.getElementById("az-html-fix-import").remove();
      }
    }

    return (
      <Modal
        title={null}
        footer={null}
        className={"bookingModal REACT-FIX-ReCAPTCHA-V2I"}
        visible={this.props.visible}
        width={1000}
        style={{
          maxWidth: "95%",
        }}
        closable
        onCancel={this.props.onClose}
        onCancel={() => {
          this.props.onCancel();
        }}
      >
        {/* <ReCaptcha
					ref={ref => this.recaptcha = ref}
					sitekey="6Ldc-4oaAAAAAPilhwB1rt2J2nfpb7r01YRpXCMQ"
					action='action_name'
					size="invisible"
					// verifyCallback={verifyCallback}
				/> */}
        <ReCAPTCHA
          ref={(ref) => (this.recaptcha = ref)}
          ref={recaptchaRef}
          size="invisible"
          sitekey="6Lfx7rUaAAAAAPu24YCFeOow28UnGMENOC_lRjAZ"
          onChange={this.sendOrder}
        />
        <Modal
          className={"bookingResultModal"}
          visible={true}
          visible={this.props.visible && this.state.page3}
          title={null}
          footer={null}
          onCancel={() => {
            Fthis.setState({
              page3: false,
            });
            setTimeout(() => {
              this.props.onCancel();
            }, 0);
          }}
        >
          <div style={{ textAlign: "center", padding: 42 }}>
            <Title level={5}>{"感謝您的登記！"}</Title>
            <Title level={5}>
              {
                "「康心摰友」專員會盡快與您聯絡，確認測試的詳細地點、日期及時間。請耐心等候!"
              }
              <br />
              {"已選擇的測試中心地點、日期及時間，有可能因應實際情況而更改。"}
            </Title>
            <Title level={5} style={{ textTransform: "lowercase" }}>
              {
                "如有任何疑問，請致電「康心摯友」熱線 : 2941 5388查詢( 星期一至五  9:00 am  to 5:00 pm ) 謝謝！"
              }
            </Title>
            <Title level={5} style={{ display: "none" }}>
              {""}
            </Title>
            <div style={{ height: 20 }}></div>
            <Button
              type="primary"
              style={{ filter: "hue-rotate(90deg)" }}
              onClick={() => {
                Fthis.setState({
                  page3: false,
                });
                setTimeout(() => {
                  this.props.onCancel();
                }, 0);
              }}
            >
              {"返回網站"}
            </Button>
          </div>
        </Modal>
        <div
          className={"Booking forxiga-pop"}
          style={{
            color: "rgb(0 0 0 / 80%)",
            filter: "hue-rotate(90deg)",
          }}
        >
          <Row>
            <Col span={0} sm={24}>
              <img
                style={{
                  marginBottom: 15,
                  filter: "hue-rotate(-90deg)",
                  width: "100%",
                }}
                // src={
                //   `https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/azhealthclub_fb_20210824_v13-07.jpg?${currentVersion()}`
                // }
                src={
                  `https://login.az.yubis.net/sites/default/files/2022-04/azhealthclub_fb_20210824_v13-07.jpeg?${currentVersion()}`
                }
                alt=""
              />
            </Col>
            <Col span={24} sm={0}>
              <img
                style={{
                  marginBottom: 15,
                  filter: "hue-rotate(-90deg)",
                  width: "100%",
                }}
                // src={
                //   `https://azhealthclub.com.hk/sites/default/files/imce_upload/admin/design-files/Forxiga/azhealthclub_fb_20210824_v13_900x900-08.jpg?${currentVersion()}`
                // }
                src={
                  `https://login.az.yubis.net/sites/default/files/2022-04/azhealthclub_fb_20210824_v13_900x900-08.jpeg?${currentVersion()}`
                }
                alt=""
              />
            </Col>
          </Row>

          <Title
            style={{
              textAlign: "center",
              fontSize: 18,
              fontWeight: 600,
              color: "rgb(0 0 0 / 80%)",
            }}
          >
            {"請填寫以下表格"}
            <br />
            {"揀選測試地點、日期及時間："}
          </Title>
          <Form
            name="basic"
            initialValues={this.state.initialValues}
            onFinish={(event) => {
              console.log(event);
              this.validate(event);
            }}
            onFinishFailed={(event) => {
              console.log(event);
            }}
            onFieldsChange={(event) => {
              let item = event[0].name[0];
              let value = event[0].value;
              if (item == "location") {
                console.debug("onFieldsChange", value);
                let currentLocation = value;

                for (let item of this.state.regionNames || []) {
                  if (item.id == value) {
                    currentLocation = item.name;
                  }
                }
                Fthis.setState({
                  currentLocation,
                  initialValues: {
                    time: "",
                  },
                });
              }
            }}
            ref={this.formRef}
          >
            <Form.Item
              name="username"
              rules={[
                // { required: debug ? false : true, message: '必填' },
                { required: false, message: "必填" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    try {
                      if (value.trim().length > 0) {
                        return Promise.resolve();
                      }
                    } catch (error) { }
                    return Promise.reject(new Error("必填"));
                  },
                }),
              ]}
            >
              <Input
                //  size="large"
                disabled={window.loadedOnce}
                placeholder={"姓名"}
              />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[{ required: debug ? false : true, message: "必填" }]}
            >
              <Input
                // size="large"
                disabled={window.loadedOnce}
                placeholder={"聯絡電話"}
                // type="number"
                onChange={this.handlePhone}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: debug ? false : true, message: "必填" }]}
            >
              <Input
                //  size="large"
                disabled={window.loadedOnce}
                placeholder={"電郵"}
              />
            </Form.Item>

            <Form.Item
              label="選擇測試中心地區"
              name="location"
              dependencies={["time"]}
              rules={[{ required: debug ? false : true, message: "必填" }]}
            // hasFeedback
            >
              <Select
                // size="large"
                disabled={window.loadedOnce}
                // showSearch
                onDropdownVisibleChange={this.onDropdownVisibleChange}
                // onBlur={()=>{console.log("onBlur")}}

                loading={!Array.isArray(this.state.regionNames)}
              // style={{ width: 120 }}
              >
                {Array.from(this.state.regionNames || []).map((item) => {
                  return <Option value={`${item.id}`}>{item.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item
              shouldUpdate
              label="選擇測試日期及時間"
              dependencies={["location"]}
              name="time"
              rules={[
                { required: debug ? false : true, message: "必填" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.debug("validator", _, value);
                    console.debug("Fthis.state", Fthis.state);
                    if (typeof Fthis.state.currentLocation != "string") {
                      return Promise.resolve();
                    } else {
                      if (
                        Array.isArray(
                          Fthis.state.regions[Fthis.state.currentLocation]
                        )
                      ) {
                        let hit = false;
                        for (let item of Fthis.state.regions[
                          Fthis.state.currentLocation
                        ]) {
                          // let id = new Date(item.begin).toISOString()
                          if (item.id == value && item.left > 0) {
                            hit = true;
                          }
                        }
                        if (hit) {
                          return Promise.resolve();
                        } else {
                          try {
                            document
                              .getElementsByClassName("biki-date-select-2")[0]
                              .getElementsByClassName(
                                "ant-select-selection-item"
                              )[0].innerText = "";
                          } catch (error) { }

                          return Promise.reject(new Error("請選擇時間"));
                        }
                      } else {
                        return Promise.resolve();
                      }
                    }
                  },
                }),
              ]}
            >
              {(() => {
                // return <Input placeholder="Basic usage" value={Fthis.state.currentLocation} key={Fthis.state.currentLocation} id={"nmsllll"} />
                return (
                  <Select
                    // showSearch
                    //  size="large"
                    // style={{ width: 120 }}
                    disabled={window.loadedOnce}
                    loading={!Array.isArray(this.state.regionNames)}
                    onDropdownVisibleChange={this.onDropdownVisibleChange}
                    disabled={typeof Fthis.state.currentLocation != "string"}
                    className={"biki-date-select-2"}
                  >
                    {Array.from(
                      (function () {
                        try {
                          if (
                            Array.isArray(
                              Fthis.state.regions[Fthis.state.currentLocation]
                            )
                          ) {
                            let array =
                              Fthis.state.regions[Fthis.state.currentLocation];
                            array.sort(function (a, b) {
                              return (
                                new Date(a.startTime).valueOf() -
                                new Date(b.startTime).valueOf()
                              );
                            });
                            return array;
                          }
                        } catch (error) {
                          // console.debug(error)
                        }
                        return [];
                      })()
                    ).map((item) => {
                      if (!this.isVaildTime(item.begin, item.regEndTime)) {
                        return "";
                      }
                      // let date = new Date(item.begin).toLocaleDateString("zh-TW", { year: "numeric", month: "long", day: "numeric" })
                      let date = new Date(item.begin).toLocaleDateString(
                        "zh-TW",
                        { month: "long", day: "numeric" }
                      );
                      let week = new Date(item.begin)
                        .toLocaleDateString("zh-TW", { weekday: "short" })
                        .replace("週", "");
                      let timeStart = new Date(item.begin).toLocaleTimeString(
                        "en-US",
                        { hour12: true, hour: "numeric", minute: "numeric" }
                      );
                      let timeEnd = new Date(item.end).toLocaleTimeString(
                        "en-US",
                        { hour12: true, hour: "numeric", minute: "numeric" }
                      );
                      let id = item.id;
                      let disabled = !(item.left > 0);


                      if (item.limits < 0) {
                        return 
                        // return <div>{`--${date}(${week}) ${timeStart} - ${timeEnd}(已滿額)${item.id})_-:${item.limits}`}</div>
                        // return <div>{`-${date}(${week}) ${timeStart} - ${timeEnd}:${item.limits}:${item.limits}`}</div>
                      }


                      if (disabled) {
                        return (
                          <Option disabled={disabled} value={id}>
                            {/* {`${date}(${week}) ${timeStart} - ${timeEnd}(已滿額):${item.id}`} */}
                            {`${date}(${week}) ${timeStart} - ${timeEnd}(已滿額)`}
                          </Option>
                        );
                      } else {
                        return (
                          <Option disabled={disabled} value={id}>
                            {/* {`${date}(${week}) ${timeStart} - ${timeEnd}:${item.id}`} */}
                            {`${date}(${week}) ${timeStart} - ${timeEnd}`}
                          </Option>
                        );
                      }
                    })}
                  </Select>
                );
              })()}
            </Form.Item>

            <div style={{ textAlign: "center", marginBottom: 24 }}>
              {"注意："}
              <br />
              {
                "已選擇的測試中心地區、日期及時間，有可能因應實際情況而更改，「康心摯友」專員會盡快與您聯絡，以確認您的登記。"
              }
            </div>

            <Form.Item
              name="check1"
              valuePropName="checked"
              rules={[
                {
                  // required: debug ? false : true,
                  required: false,
                  message: "必須勾選",
                },
              ]}
            >
              <Checkbox disabled={window.loadedOnce} size="large">
                {
                  "本人願意接收有關阿斯利康健康講座/活動、藥物產品、會員優惠、健康醫學及來自阿斯利康關聯保健/醫藥機構的資料，並同意可為該目的使用本人個人資料。"
                }
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="check2"
              valuePropName="checked"
              rules={[
                {
                  // required: debug ? false : true,
                  required: false,
                  message: "",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.debug("validator", value);
                    if (value == true) {
                      return Promise.resolve();
                    } else {
                      console.debug("value", value);
                      return Promise.reject(new Error("必須勾選"));
                    }
                  },
                }),
              ]}
            >
              <Checkbox size="large" disabled={window.loadedOnce}>
                {"本人已閱讀及同意「康心摯友」有關 "}
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    window.open(
                      "https://login.az.yubis.net/index/fb_privacy_statement"
                    );
                  }}
                >
                  {"私隱政策聲明"}
                </a>
                {" 及 "}
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    window.open(
                      "https://login.az.yubis.net/index/forxigadisclaimer"
                    );
                  }}
                >
                  {"免責聲明"}
                </a>
                {" 之條款。"}
              </Checkbox>
            </Form.Item>

            <div style={{ textAlign: "center" }}>
              {/* {"(如以上方格沒有打剔，阿斯利康未得到本人同意，有關登記將不被接納)"} */}
              {/* <br /> */}
              {
                "如有任何疑問，請致電「康心摯友」熱線 : 2941 5388查詢 ( 星期一至五  9:00 am  to 5:00 pm ) 謝謝！"
              }
            </div>
            <Form.Item>
              <div
                style={{ width: "100%", textAlign: "center", marginTop: 24 }}
              >
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ width: 250 }}
                  disabled={window.loadedOnce}
                  loading={this.state.loading}
                >
                  {"提交"}
                </Button>
              </div>
            </Form.Item>
          </Form>
          {"HK-5884 09/09/2021"}
        </div>
      </Modal>
    );
  }
  componentWillUnmount() { }
}

export { Booking };
