import React, { PureComponent } from "react";
import { createGUID } from './requester';
import { Modal } from 'antd';
import YouTube from 'react-youtube';
import { Typography } from 'antd';

const { Title } = Typography;


class TipsOverlay extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.GUID = createGUID();
		this.state = {
		};
	}
	componentDidMount () {
		let Fthis = this;

	}
	render () {
		let Fthis = this;
		return (
			<>
				<Modal
					title={null}
					footer={null}
					visible={this.props.visible}
					// visible={false}
					width={800}
					style={{
						maxWidth: "95%",
						// minWidth: 450,
					}}
					onCancel={this.props.onClose}
				// forceRender
				>
					<img src={"https://login.az.yubis.net/sites/default/files/imce_upload/admin/HKSR-Logo-2020.png"} style={{ height: 120 }} />
					<div
						style={{ textAlign: "center", padding: 24 }}
					>

						<Title level={4} style={{ fontSize: 16, fontWeight: 300 }}>{"以下資料由復康會提供"}</Title>

						<Title level={2} style={{ color: "#e53f3e", marginTop: 0, fontWeight: 700 }} className={"title-css-experiment"}>
							<span>{"要血糖穩定 做好自我管理最緊要"}</span>
						</Title>

						<img src={"https://z3.ax1x.com/2021/04/27/g9sCWR.jpg"} style={{ width: "100%" }} />

						<Title level={2} style={{ marginTop: 48, color: "#e53f3e" }} className={"title-css-experiment"}>
							<span>{"“至醒糖人” 秘笈"}</span>
						</Title>
						<Title level={5} style={{ fontSize: 24 }}>{"立即睇片："}</Title>
						{/* <YouTube
							videoId={"LfYeLIMPvm4"}
							id={this.GUID}
							opts={{ height: '515', width: '100%', playerVars: { autoplay: 0, }, }}
						// onReady={this.YouTubeReady}						// onPlay={this.YouTubeReady}						// onEnd={(event) => { console.log("onEnd", event); }}						// onStateChange={this.YouTubeStateChange}
						/> */}
						<a href={"https://www.youtube.com/watch?v=LfYeLIMPvm4&list=PLyCHOqHnsrPcCNgJhslvdc4E0WFvdqZpj"} style={{ fontSize: 24 }}>
							<div style={{
								display: "flex",
								alignItems: "center",
								flexWrap: "nowrap",
								alignContent: "center",
								justifyContent: "center"
							}}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ display: "inline-block", width: 67, height: "100%" }}>
										<img src={"https://login.az.yubis.net/sites/default/files/imce_upload/admin/800px-YouTube_full-color_icon_(2017).svg.png"} style={{ height: 40, marginRight: 10 }} />
									</div>
									<div style={{ display: "inline-block", width: "calc(100% - 67px)", textAlign: "left", wordBreak: "break-all" }}>
										{"https://www.youtube.com/watch?v=LfYeLIMPvm4"}
									</div>
								</div>
							</div>

						</a>
						<Title level={2} style={{ marginTop: 32, marginBottom: 24, color: "#e53f3e" }} className={"title-css-experiment"}>
							<span>{"自主控糖 教室"}</span>
						</Title>
						<img src={"https://z3.ax1x.com/2021/04/27/g9siS1.jpg"} style={{ width: "100%" }} />
						<br />
						<br />
						<br />
						<img src={"https://z3.ax1x.com/2021/04/27/g9sFQx.jpg"} style={{ width: "100%" }} />
						<div style={{ marginTop: 24, color: "#e53f3e", fontSize: 18 }}>
							{"👉更多詳情請参閱復康會網站： "}
							<a href={"https://www.rehabsociety.org.hk/zh-hant/"} style={{ color: "#e53f3e" }}>{"https://www.rehabsociety.org.hk/zh-hant/"}</a>
						</div>
					</div>
					<div style={{ marginLeft: 24, paddingBottom: 14 }}>
						{"HK-5382  10/05/2021"}
					</div>
				</Modal>

			</>
		);
	}
	componentWillUnmount () {

	}
}


export { TipsOverlay };
