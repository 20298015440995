import React, { PureComponent } from "react";
import { createGUID } from './requester';
import { Row, Col, Modal, Radio, Button } from 'antd';
import { RBackground } from './requester';
import DPlayer from 'react-dplayer';

import {
	PlayCircleOutlined
} from '@ant-design/icons';
import Dailymotion from 'react-dailymotion';
import ReactGA from 'react-ga';
import { Player } from "./vidCard";


ReactGA.initialize('UA-41104597-2');
class VideoOverlay extends PureComponent {
	constructor(props = {
		video: [
			{
				name: '',
				url: '',
				type: '',
			},
		],
		visible: true,
		popup: true,
	}) {
		super(props);
		this.ref = React.createRef();
		this.GUID = createGUID();
		this.state = {
			picBlobUrl: undefined,
		};
		this.YouTubeReady = function () { }
	}
	componentDidMount () {
		let Fthis = this;
		this.setState({
			ref: this.ref,
		})
		////////////////////////
		this.YouTubeReady = function (event) {
			let dom = document.getElementById(Fthis.GUID)
			Fthis.PLayerDom = dom
			Fthis.PLayerTarget = event.target


			var timer = setInterval(() => {
				try {
					dom.parentElement.style.height = "100%"
					Fthis.ref.current.style.display = "block"
					clearInterval(timer)
				} catch (error) {
				}
			}, 30);
		}
		this.YouTubeStateChange = function (event) {
			console.log("YouTubeStateChange", event);
			if (event.data == 2 || event.data == 0) {
				Fthis.setState({ pause: true })
			} else {
				Fthis.setState({ pause: false })
			}
		}

		////////////////////////////////////
		this.tracker = function (eventName, uid = "default") {
			console.debug("tracker", eventName)

			ReactGA.event({
				category: 'User',
				action: `video_tnb_${eventName}(${uid})`
			});
			if (typeof (window.dataLayer) == "object" && typeof (window.dataLayer.push) == "function") {
				window.dataLayer.push({ 'event': `video_tnb_${eventName}(${uid})` });
			} else {
				console.error("gtm not ready")
			}
		}
	}
	render () {
		let Fthis = this;
		if (this.props.visible != this.state.visible) {
			Fthis.setState({ visible: Fthis.props.visible })
			if (this.props.visible != true) {

				try {
					Fthis.PLayerTarget.pauseVideo()
				} catch (error) {

				}
			}
		}

		let fs = 1
		if (navigator.userAgent.indexOf("Android") == -1) {
			fs = 0
		}
		if (this.props.popup) {
			let width = "max(41.666vw,800px)"
			let height = "max(24.375vw,465px)"
			return <Modal
				visible={this.props.visible}
				title={null}
				footer={null}
				closable={false}
				width={width}
				height={height}
				onCancel={this.props.onClose}
				// forceRender
				destroyOnClose
			>
				<div ref={this.ref} style={{
					position: "relative",
					lineHeight: 0,
					width: width,
					maxWidth: "calc(100vw - 16px)",
					maxHeight: "calc(100vw - 16px)",
					height: height,
					background: "black"
				}}>
					{/* fix: Play组件内部Iframe写死了地址 不能使用 */}
					{/* <DPlayer options={{
						video: {
							quality: this.props.video,
							defaultQuality: 0,
						},
						screenshot: true
					}} /> */}
					<Player
						defaultQuality={Array.isArray(this.props.video) ? Math.min(2, this.props.video.length - 1) : 0}
						video={this.props.video}
						autoPLay={true}
						defaultMute={true}
					/>
					{/* <Player
						defaultQuality={Array.isArray(this.props.video) ? Math.max(this.props.video.length - 2, 0) : 0}
						video={this.props.video}
						autoPLay={true}
					/> */}
				</div>
			</Modal>
		} else {
			return (<div ref={this.ref} style={{ position: "relative", lineHeight: 0, width: "100%", height: "100%", }}>
				<Player
					defaultQuality={Array.isArray(this.props.video) ? Math.min(2, this.props.video.length - 1) : 0}
					video={this.props.video}
					autoPLay={true}
					defaultMute={true}
				/>
			</div>)
		}

	}
	componentWillUnmount () {

	}
}

export { VideoOverlay };
