import React, { PureComponent } from "react";
import { createGUID } from './requester';
import DPlayer from 'react-dplayer';


class VidCard extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.GUID = createGUID();
		this.state = {
			picBlobUrl: undefined,
		};
	}
	componentDidMount () {
		let Fthis = this;

	}
	render () {
		let Fthis = this;

		return (
			<>
				<div style={{ position: "absolute", top: 0, left: 0, color: "white", fontWeight: 500, fontSize: 30, background: "#71b95375", width: 360, height: 388 }}>
					{"VID"}
					<div style={{ position: "absolute", top: 216, left: 19, color: "white", fontWeight: 500, fontSize: 30, color: "black" }}>
						{"title"}
					</div>
					<div style={{ position: "absolute", top: 296, left: 19, color: "white", fontWeight: 200, fontSize: 30, color: "black" }}>
						{"des"}
					</div>
				</div>

			</>
		);
	}
	componentWillUnmount () {

	}
}
class VidOverlay extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.GUID = createGUID();
		this.state = {
			picBlobUrl: undefined,
		};
	}
	componentDidMount () {
		let Fthis = this;

	}
	render () {
		let Fthis = this;

		return (
			<>
				<div style={{ position: "absolute", top: 0, left: 0, color: "white", fontWeight: 500, fontSize: 30, background: "#71b95375", width: 358, height: 388 }}>
					{"VID"}
					<div style={{ position: "absolute", top: 216, left: 19, color: "white", fontWeight: 500, fontSize: 30, color: "black" }}>
						{"title"}
					</div>
					<div style={{ position: "absolute", top: 296, left: 19, color: "white", fontWeight: 200, fontSize: 30, color: "black" }}>
						{"des"}
					</div>
				</div>
				<div style={{ position: "absolute", top: 0, left: 390, color: "white", fontWeight: 500, fontSize: 30, background: "#71b95375", width: 358, height: 388 }}>
					{"VID"}
				</div><div style={{ position: "absolute", top: 0, left: 390 * 2, color: "white", fontWeight: 500, fontSize: 30, background: "#71b95375", width: 358, height: 388 }}>
					{"VID"}
				</div>
			</>
		);
	}
	componentWillUnmount () {

	}
}
class Player extends PureComponent {
	constructor(props = {
		video: [
			{
				name: '',
				url: '',
				type: '',
			},
		],
		defaultQuality: 0,
		autoPLay: false,
		defaultMute: false,
	}) {
		super(props);
		this.ref = React.createRef();
		this.GUID = createGUID();
		this.state = {
		};
	}
	componentDidMount () {
		let Fthis = this;
		console.log("this.ref.current", this.ref.current)

	}
	render () {
		let Fthis = this;

		return (
			// <div ref={this.ref}>

			// <iframe
			// 	src={`https://vidplayer.pages.dev/?video=${btoa(JSON.stringify(this.props.video))}&autoplay=true`}
			// 	style={{ width: "100%", height: "100%" }}
			// 	frameBorder="0"
			// 	allowFullScreen="true"
			// />
			// eslint-disable-next-line jsx-a11y/iframe-has-title
			<iframe
				style={{ width: "100%", height: "100%" }}
				{...this.props.video}></iframe>
			// {/* <DPlayer
			// contextmenu={[]}
			// 	screenshot={true}
			// 	options={{
			// 		autoplay: this.props.autoPLay,
			// 		volume: this.props.defaultMute ? 0 : undefined,
			// 		video: {
			// 			quality: this.props.video,
			// 			defaultQuality: this.props.defaultQuality,
			// 		}
			// 	}}
			// /> */}
			// </div>
		);
	}
	componentWillUnmount () {

	}
}

export { Player };
